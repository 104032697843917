import { Component, Inject, Input, LOCALE_ID, PipeTransform } from "@angular/core";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";

import { BaseDisclosureInfo } from "../apr-calculator.model";

@Component({
    selector: 'ifa-disclosure-field',
    templateUrl: './disclosure-field.component.html',
    styleUrls: ['./disclosure-field.component.scss']
})
export class DisclosureFieldComponent {
    private _pipes: any;
    private _pipe: PipeTransform;
    private _pipeFormat: string;
    private _pipeFormatSet: boolean = false;

    @Input() data: BaseDisclosureInfo;
    @Input() field: string;
    @Input() format: string;
    @Input() value: string;

    constructor(@Inject(LOCALE_ID) locale: string) {
        this._pipes = {
            'currency': new CurrencyPipe(locale),
            'number': new DecimalPipe(locale),
            'percent': new PercentPipe(locale)
        };
    }

    get text(): string {
        return this.value ? this.value : this.transform(this.data[this.field]);
    }

    get tooltip(): string {
        return this.data[`${this.field}Tooltip`];
    }

    private get pipe(): PipeTransform {
        if(!this._pipeFormatSet) {
            this.setPipeFormat();
        }

        return this._pipe;
    }

    private get pipeFormat(): string {
        if(!this._pipeFormatSet) {
            this.setPipeFormat();
        }

        return this._pipeFormat;
    }

    private setPipeFormat(): void {
        const idx = this.format?.indexOf(':');
        if(idx !== null && idx > -1) {
            this._pipeFormat = this.format.substring(idx + 1).replace(/\'/g, "");
            this._pipe = this._pipes[this.format.substring(0, idx)];
        }
        else {
            this._pipeFormat = null;
            this._pipe = this._pipes[this.format];
        }
    }

    private transform(value?: any): string {
        return this.pipe ? this.pipe.transform(value, this.pipeFormat) : value;
    }
}