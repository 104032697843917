<div class="summary-offer-widget">
  <h3>
    {{bundle.offers[0]?.productName}}
  </h3>
  <mat-card class="mat-elevation-z2" [ngClass]="{
    'loc-card':  type === offerType.LOC,
    'term-card':  type === offerType.Term,
    'law-card': type === offerType.Law
  }" (click)="detailLink()">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
      <div style="position: relative; z-index:999;" fxLayoutAlign="center center" fxFlex="7">
        <mat-icon *ngIf="type === offerType.LOC" class="mat-white">data_saver_off</mat-icon>
        <mat-icon *ngIf="type === offerType.Term" class="mat-white">assessment</mat-icon>
        <mat-icon *ngIf="type === offerType.Law" class="mat-white">work</mat-icon>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between left" fxFlex="80">
          <span class="offer-value">{{getAmount() | currency:'$'}}</span>
          <span class="offer-title">{{getAmountTitle()}}</span>
          <span class="offer-value">{{bundle.offers[0]?.repaymentTerm}} months</span>
          <span class="offer-title">Repayment term</span>
          <div>
          <button mat-button (click)="detailLink()">SEE ALL OFFERS</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
