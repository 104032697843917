<nav class="productNav" ngClass.lt-sm="productNav-sm">
    <button *ngIf="locOfferBundle" (click)="click(locOfferBundle)" [ngClass]="{active: isCurrentLocProduct}">
        <mat-icon>data_saver_off</mat-icon>
        <div>
            LINE OF CREDIT
        </div>
    </button>
    <button *ngIf="lawOfferBundle" (click)="click(lawOfferBundle)" [ngClass]="{active: isCurrentLawProduct}">
        <mat-icon>assessment</mat-icon>
        <div>
            CASE FINANCING
        </div>
    </button>
    <button *ngIf="termOfferBundle" (click)="click(termOfferBundle)" [ngClass]="{active: isCurrentTermProduct}">
        <mat-icon>assessment</mat-icon>
        <div>
            TERM LOAN
        </div>
    </button>
</nav>
