import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Lead } from '../../lead.data';
import { LeadService } from '../../lead.service';

@Component({
  selector: 'ifa-business-information',
  templateUrl: './business-information.component.html',
  styleUrls: ['./business-information.component.scss']
})
export class BusinessInformationComponent implements OnInit, OnDestroy, AfterViewInit {

  private _unsubscribeAll: Subject<any>;

  @ViewChild("firstName", { static: true }) _firstName: ElementRef;

  @Input()
  form: UntypedFormGroup;

  data: Lead;

  constructor(
    private leadService: LeadService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.leadService.obsCurrentLead
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data)
          this.dataInit(data);
      });

    this.leadService.obsOwner1Email
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(value => {
        if (value) {
          this.form.controls.base.patchValue({
            email: value
          });
          this.emailChanged(value);
        }
      });
  }

  ngAfterViewInit() {
    this._firstName.nativeElement.focus();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dataInit(data: Lead) {
    this.data = data;

    if (!this.data.business || !this.data.business.details) return;
    const hasOwners = this.data.business.owners && this.data.business.owners.length > 0;
    this.form.reset({
      base: {
        firstName: hasOwners ? this.data.business.owners[0].firstName : '',
        lastName: hasOwners ? this.data.business.owners[0].lastName : '',
        email: hasOwners ? this.data.business.owners[0].email : '',
        legalEntityName: this.data.business.details.legalEntityName || ''
      },
      taxId: this.data.business.details.taxId || '',
      referralCode: this.data.business.details.referralCode || '',
      phone: this.data.business.details.phoneNumber || '',
      address: {
        street: this.data.business.details.address.street || '',
        city: this.data.business.details.address.city || '',
        state: this.data.business.details.address.state || '',
        zipCode: this.data.business.details.address.zipCode || '',
        unitNumber: this.data.business.details.address.unitNumber || ''
      },
      averageMonthlySales: this.data.business.details.averageMonthlySales || '',
      timeInBusiness: this.data.business.details.timeInBusiness || '',
      entityType: this.data.business.details.entityType || ''
    });
  }

  emailChanged(value): void {
    window.analytics.identify(value);
  }

}
