<link [href]="brandingCss" rel="stylesheet">

<div id="ifa-calc-header-background" class="ifa-calc-background">
  <div #confettiCanvas></div>
</div>

<div class="ifa-calc-wrapper" fxLayout="column">

  <div fxLayout="column" fxLayoutAlign="start center">

    <h2>
      Congratulations!
    </h2>

    <h4 class="layout padding-left padding-right">
      {{application?.businessName}} have offers for financing up to<span *ngIf="bundles?.length" class="highlighted"> {{getMaxOffer() | currency:'$'}}</span> 
      and terms up to<span *ngIf="bundles?.length" class="highlighted"> {{getMaxRepayment()}} months</span>. See your approved product(s) below. 
    </h4>

    <ifa-share-offer *ngIf="!isPreview" [emailBody]="shareOfferEmailBody" [class]="'shareoffer-' + bundles?.length + 'products'"></ifa-share-offer>

    <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="24px" fxLayout.lt-lg="column"
      fxLayoutAlign.lt-lg="center center" class="layout padding-top">
      <ifa-summary-offer-widget *ngFor="let bundle of bundles" [bundle]="bundle" [uuid]="application?.uuid"></ifa-summary-offer-widget>
    </div>

    <ifa-link-your-bank [applicationUuid]="uuid" [bankLinkStatus]="application?.bankLinkStatus" ></ifa-link-your-bank>

    <p class="layout padding-left padding-right" *ngIf="!isPreview">
      Call or email
      {{application?.advisorName}} at <a href="tel:{{application?.advisorPhone}}">{{application?.advisorPhone}}</a> or
      <a href="mailto:{{application?.advisorEmail}}">{{application?.advisorEmail}}</a> and let them know which offer you have selected.
    </p>

  </div>

</div>
