import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { ApplicationBaseData, ApplicationOffer, DomHelper, parseNumber } from 'common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from '../application.service';
import { SendOfferEmailComponent } from './send-offer-email/send-offer-email.component';

@Component({
  selector: 'ifa-share-offer',
  templateUrl: './share-offer.component.html',
  styleUrls: ['./share-offer.component.scss']
})
export class ShareOfferComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;

  @Input() offer: ApplicationOffer;
  @Input() emailBody: string;

  application: ApplicationBaseData
  uuid: string
  bundleId: number
  offerId: number
  shareUrl: string
  customerUrlTree: UrlTree

  constructor(
    private dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _applicationService: ApplicationService,
    private _snackBar: MatSnackBar
  ) { 
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this._route.queryParamMap.subscribe(params => {
      this.uuid = params.get("uuid");
      this.bundleId = parseNumber(params.get("bundleId"));

      this._applicationService.getByUUID(this.uuid)
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(this.dataInit.bind(this));
      });
  }

  private dataInit(data: ApplicationBaseData) {
    this.application = data;
    this._route.url.subscribe(url=>{
      this.customerUrlTree = this._router.createUrlTree(['preview/' + url[0].path], { queryParams: {uuid: this.uuid, bundleId: this.bundleId}});
      this.shareUrl =  this._router['location']._locationStrategy._platformLocation.location.origin + this.customerUrlTree.toString();
    });
    
}
  
  sendEmailOffer(): void {
    SendOfferEmailComponent.show(this.dialog, this.application, this.shareUrl, this.offer, this.emailBody);
  }

  copy(): void {
    DomHelper.copy(this.shareUrl);
    this._snackBar.open(`Link copied`, '',
      { duration: 5000 });
  }

  customerView(): void {
    this._route.url.subscribe(_url=>{
      this._router.navigateByUrl(this.customerUrlTree);
    });
  }

}
