<div fxLayout="column"
     fxLayoutAlign="start center"
     *ngIf="bankLinkStatus === BankLinkStatus.Unlinked || !bankLinkStatus">
  <p *ngIf="!displayOnlyButton"
     class="text">For faster access to funds, securely link and verify your bank with <a href="https://plaid.com/">PLAID</a>.</p>
  <div>
    <button mat-button
            class="rounded-button link"
            (click)="linkBank()"
            *ngIf="!isBankLinked && !bankLinkerRunning">
      <mat-icon>link</mat-icon>
      Link your bank
    </button>
    <button mat-button
            class="rounded-button linking"
            *ngIf="bankLinkerRunning">
      <span class="loader-1"></span>
      Please wait...
    </button>
    <button mat-button
            class="rounded-button linked"
            *ngIf="isBankLinked">
      <mat-icon>check_circle</mat-icon>
      Bank linked
    </button>
  </div>
