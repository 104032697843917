<link [href]="brandingCss" rel="stylesheet">

<div class="ifa-calc-wrapper" fxLayout="column">

  <ifa-product-navigator *ngIf="navigator?.bestLocOfferBundle || navigator?.bestTermOfferBundle" [offerNavigator]="navigator" [customerView]="customerView"></ifa-product-navigator>
  
  <ifa-repayment-navigator *ngIf="navigator?.lawOfferBundles && navigator?.lawOfferBundles.length > 1" [offerBundles]="navigator?.lawOfferBundles"
    [currentOfferBundle]="navigator?.currentOfferBundle" [customerView]="customerView"></ifa-repayment-navigator>

  <h4 class="layout padding-left padding-right">
    <ifa-calculator-heading [businessName]="bundle?.businessName"></ifa-calculator-heading>
  </h4>

  <ifa-law-case-financing-content [offer]="offer" [customerView]="customerView"></ifa-law-case-financing-content>

</div>
