export class LeadBusinessData {
    leadSource?: string;
    leadSourceDetail?: string;
    irclickId?: string;
    owners?: LeadBusinessOwnerData[] = [];
    details?: BusinessDetail;
}

export class Lead {
    accessToken: string;
    business = new LeadBusinessData();
    submitted?: any;
}

export class BusinessDetail {
    legalEntityName?: string;
    taxId?: string;
    referralCode?: string;
    address?: Address;
    phoneNumber?: string;
    averageMonthlySales?: number;
    timeInBusiness?: number;
    entityType?: string;
}

export interface Address {
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    country?: string;
    unitNumber?: string;
}

export interface BirthDate {
    day?: string;
    month?: string;
    year?: string;
}

export interface LeadBusinessOwnerData {
    firstName?: string;
    lastName?: string;
    ssn?: string;
    email?: string;
    phoneNumber?: string;
    address?: Address;
    birthDate?: BirthDate;
    agreedToTerms?: boolean;
    ficoScore?: number;
    ownership?: number;
}
