import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxCurrencyModule } from 'common';
import { NgChartsModule } from 'ng2-charts';

import { SharedModule } from '../shared/shared.module';
import { CalculatorRoutingModule } from './calculator-routing.module';
import { LocCalculatorComponent } from './loc/loc-calculator.component';
import { TermCalculatorComponent } from './term/term-calculator.component';
import { BundleCalculatorComponent } from './bundle/bundle-calculator.component';
import { SummaryCalculatorComponent } from './summary/summary-calculator.component';
import { SummaryOfferWidgetComponent } from './summary/offer-widget/summary-offer-widget.component';
import { LawCalculatorComponent } from './law/law-calculator.component';
import { CalculatorDisclosuresComponent } from './disclosures/calculator-disclosures.component';
import { LinkYourBankModule } from '../shared/link-your-bank/link-your-bank.module';
import { AprCalculatorModule } from './apr/apr-calculator.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    NgxCurrencyModule.forRoot({
      align: "left",
      allowNegative: false,
      allowZero: true,
      decimal: ".",
      precision: 2,
      prefix: "$",
      suffix: "",
      thousands: ",",
      nullable: true
    }),
    NgChartsModule,
    SharedModule,
    CalculatorRoutingModule,
    LinkYourBankModule,
    AprCalculatorModule
  ],
  declarations: [
    LocCalculatorComponent,
    TermCalculatorComponent,
    BundleCalculatorComponent,
    LawCalculatorComponent,
    SummaryOfferWidgetComponent,
    SummaryCalculatorComponent,
    CalculatorDisclosuresComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class CalculatorModule { }
