import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { Lead } from './lead.data';
import { QueryResult } from '../shared/query';
import { tap, map, mergeMap } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  onCurrentLeadChanged: ReplaySubject<Lead>;
  obsCurrentLead: Observable<Lead>;

  onOwner1EmailChanged: ReplaySubject<string>;
  obsOwner1Email: Observable<string>;

  constructor(
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private settings: AppSettings
  ) {
    this.formInit();

    this.onCurrentLeadChanged = new ReplaySubject<Lead>(1);
    this.obsCurrentLead = this.onCurrentLeadChanged.asObservable();

    this.onOwner1EmailChanged = new ReplaySubject<string>(1);
    this.obsOwner1Email = this.onOwner1EmailChanged.asObservable();
  }

  form: UntypedFormGroup;

  private formInit() {
    this.form = this.formBuilder.group({});
  }

  create(lead: Lead): Observable<Lead> {
    const url = `${this.settings.leads.url}/api/lead`;
    return this.http.post<Lead>(url, lead);
  }

  current(accessToken?: string, leadSource?: string, leadSourceDetail?: string, irclickId?: string): Observable<Lead> {
    const leadFromSession = this.getLeadFromSession();

    if (leadSource)
      sessionStorage.setItem('leadSource', leadSource);
    else
      leadSource = sessionStorage.getItem('leadSource');

    if (leadSourceDetail)
      sessionStorage.setItem('leadSourceDetails', leadSourceDetail);
    else
      leadSourceDetail = sessionStorage.getItem('leadSourceDetails');

    if (irclickId)
      localStorage.setItem('irclickId', irclickId);
    else
      irclickId = sessionStorage.getItem('irclickId');

    const leadSourceUpdate = tap((lead: Lead) => {
      if (!lead)
        return;

      if (!lead.business)
        lead.business = {};

      if (leadSource)
        lead.business.leadSource = leadSource;

      if (leadSourceDetail)
        lead.business.leadSourceDetail = leadSourceDetail;

      if (irclickId)
        lead.business.irclickId = irclickId;
    });

    const leadCreate = this.create(new Lead()).pipe(
      map(_ => _),
      leadSourceUpdate,
      tap(lead => {
        this.setCurrent(lead);
      }),
    );

    const leadGet = this.query(accessToken).pipe(
      map(_ => _.records.length ? _.records[0] : null),
      mergeMap(lead => {
        if (lead && !lead.submitted) {
          this.setCurrent(lead);
          return of(lead).pipe(leadSourceUpdate);
        }
        return leadCreate;
      })
    );

    if (accessToken && (!leadFromSession || leadFromSession.accessToken !== accessToken))
      return leadGet;

    return leadFromSession
      ? of(leadFromSession).pipe(leadSourceUpdate)
      : leadCreate;
  }

  private setCurrent(lead: Lead) {
    localStorage.setItem('lead', JSON.stringify(lead));
  }

  getLeadFromSession(): Lead {
    const leadFromSessionAsString = localStorage.getItem('lead');
    if (leadFromSessionAsString) {
      try {
        return JSON.parse(leadFromSessionAsString);
      } catch (e) {
        console.error(`Invalid lead. ${e}. ${leadFromSessionAsString}`);
      }
    }
    return null;
  }

  query(accessToken: string): Observable<QueryResult<Lead>> {
    const url = `${this.settings.leads.url}/api/lead`;
    const params: any = { accessToken };
    return this.http.get<QueryResult<Lead>>(url, { params });
  }

  submit(lead: Lead): Observable<any> {
    const url = `${this.settings.leads.url}/api/lead/submit`;
    return this.http.post<{ lead: Lead }>(url, { accessToken: lead.accessToken });
  }

  update(lead: Lead): Observable<void> {
    const url = `${this.settings.leads.url}/api/lead`;
    return this.http.put<void>(url, lead).pipe(
      tap(() => this.setCurrent(lead))
    );
  }
}
