import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'ifa-credit-application-terms-dialog',
  templateUrl: './credit-application-terms-dialog.component.html',
  styleUrls: ['./credit-application-terms-dialog.component.scss']
})
export class CreditApplicationTermsDialogComponent {

  constructor(public dialogRef: MatDialogRef<CreditApplicationTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  static show(dialog: MatDialog): Observable<any> {
    return dialog.open(CreditApplicationTermsDialogComponent, {
      width: '550px',
      closeOnNavigation: true,
    }).afterClosed();
  }
}
