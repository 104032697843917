import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: 'ifa-calculator-disclosures',
    templateUrl: './calculator-disclosures.component.html',
    styleUrls: ['./calculator-disclosures.component.scss']
})
export class CalculatorDisclosuresComponent implements OnInit {

    disclosures: any[];
    disclosureIndex: number = 0;

    constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            this.disclosures = data.disclosures.map(_ => {
                return {
                    name: _.name,
                    html: this.sanitizer.bypassSecurityTrustHtml(_.html)
                }
            })
        });
    }

    close(): void {
        window.close();
    }

    back(): void {
        if(this.disclosureIndex > 0)
            this.disclosureIndex -= 1;
    }

    next(): void {
        if(this.disclosureIndex < this.disclosures.length - 1)
            this.disclosureIndex += 1;
    }
}