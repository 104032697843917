import { Component, OnInit } from '@angular/core';
import { LocDisclosureInfo, TermDisclosureInfo } from './apr-calculator.model';
import { BrandingService, CompanyBranding } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifa-apr-calculator',
  templateUrl: './apr-calculator.component.html',
  styleUrls: ['./apr-calculator.component.scss']
})
export class AprCalculatorComponent implements OnInit {
  brandingData: CompanyBranding;

  get dbaOrName(): string {
    return this.brandingData?.dba || this.brandingData?.name;
  }

  loc: LocDisclosureInfo = new LocDisclosureInfo('', 0, 0, null, null, 0, 0, 0, 0);
  lcf: LocDisclosureInfo = new LocDisclosureInfo('LevelEsq', 0, 0, null, null, 0, 0, 0, 0);
  term: TermDisclosureInfo = new TermDisclosureInfo('', 0, 0, null, 0, 0);

  private _creditLimit?: number;
  get creditLimit(): number {
    return this._creditLimit;
  }
  set creditLimit(value: number) {
    this._creditLimit = value;
    this.recalculate();
  }

  private _interestRate?: number;
  get interestRate(): number {
    return this._interestRate;
  }
  set interestRate(value: number) {
    this._interestRate = value;
    this.recalculate();
  }

  private _paymentFrequency?: string = "weekly";
  get paymentFrequency(): string {
    return this._paymentFrequency;
  }
  set paymentFrequency(value: string) {
    this._paymentFrequency = value;
    this.recalculate();
  }

  private _drawFee?: number = 2.49;
  get drawFee(): number {
    return this._drawFee;
  }
  set drawFee(value: number) {
    this._drawFee = value;
    this.recalculate();
  }

  private _drawTerm?: number = 12;
  get drawTerm(): number {
    return this._drawTerm;
  }
  set drawTerm(value: number) {
    this._drawTerm = value;
    this.recalculate();
  }

  private _repaymentTerm?: number;
  get repaymentTerm(): number {
    return this._repaymentTerm;
  }
  set repaymentTerm(value: number) {
    this._repaymentTerm = value;
    this.recalculate();
  }

  private _interestOnlyTerm?: number = 0;
  get interestOnlyTerm(): number {
    return this._interestOnlyTerm;
  }
  set interestOnlyTerm(value: number) {
    this._interestOnlyTerm = value;
    this.recalculate();
  }

  private _renewalFee?: number = 0;
  get renewalFee(): number {
    return this._renewalFee;
  }
  set renewalFee(value: number) {
    this._renewalFee = value;
    this.recalculate();
  }

  private _otherDeferredFee?: number = 0;
  get otherDeferredFee(): number {
    return this._otherDeferredFee;
  }
  set otherDeferredFee(value: number) {
    this._otherDeferredFee = value;
    this.recalculate();
  }

  private _otherCashFee?: number = 0;
  get otherCashFee(): number {
    return this._otherCashFee;
  }
  set otherCashFee(value: number) {
    this._otherCashFee = value;
    this.recalculate();
  }

  private _balanceTransfer?: number = 0;
  get balanceTransfer(): number {
    return this._balanceTransfer;
  }
  set balanceTransfer(value: number) {
    this._balanceTransfer = value;
    this.recalculate();
  }

  private _payoff?: number = 0;
  get payoff(): number {
    return this._payoff;
  }
  set payoff(value: number) {
    this._payoff = value;
    this.recalculate();
  }

  get allDataIn(): boolean {
    if (!this._creditLimit)
      return false;

    if (!this._interestRate)
      return false;

    if (!this._paymentFrequency)
      return false;

    if (!this._repaymentTerm)
      return false;

    return true;
  }

  constructor(private brandingService: BrandingService) { }
  ngOnInit(): void {
    this.initBranding();
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this)).subscribe(data => {
        this.brandingData = data;
        this.recalculate();
      });
  }

  private recalculate(): void {
    const dbaOrName = this.brandingData?.dba || this.brandingData?.name;

    this.loc = new LocDisclosureInfo(
      dbaOrName,
      this._creditLimit, this._interestRate, this._paymentFrequency,
      this._drawTerm, this._repaymentTerm, this._interestOnlyTerm > this._repaymentTerm ? 0 : this._interestOnlyTerm,
      this._drawFee, this._renewalFee, this._otherDeferredFee,
      this._otherCashFee, this._balanceTransfer, this._payoff);

    this.lcf = new LocDisclosureInfo(
      'LevelEsq',
      this._creditLimit, this._interestRate, this._paymentFrequency,
      this._drawTerm, this._repaymentTerm, this._interestOnlyTerm > this._repaymentTerm ? 0 : this._interestOnlyTerm,
      this._drawFee, this._renewalFee, this._otherDeferredFee,
      this._otherCashFee, this._balanceTransfer, this._payoff);

    this.term = new TermDisclosureInfo(
      dbaOrName,
      this._creditLimit, this._interestRate, this._paymentFrequency,
      this._repaymentTerm,
      this._renewalFee, this._otherDeferredFee,
      this._otherCashFee, this._balanceTransfer, this._payoff);
  }
}
