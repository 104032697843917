<mat-dialog-content>
  <div>
    <span class="mat-title">Credit Application terms</span>
  </div>
  <div class="content" class="layout padding-top-md">
    <span class="text secondary-text">
      You agree that Idea 247, Inc. (“Idea Financial”) or its representatives or agents may call, text, or email you at
      the number and/or email provided above. You may opt out of receiving communications of your choice from Idea
      Financial as provided in the Privacy Policy. By clicking Continue you agree to our Terms of Service and Privacy
      Policy.<br><br>
      By signing and submitting your Credit Application (the “Application”) to Idea 247, Inc. (“Idea Financial”), you
      represent to Idea Financial, its agents and assignees, and you acknowledge and agree that (i) if the Application
      is by a company, you are authorized to apply on behalf of the company whose full legal name appears above under
      the Business & Personal Information portion of the Application for credit from us, (ii) the loan requested by the
      Application will not be used for any illegal or prohibited purpose, (iii) all information you provide within the
      Application and other supporting documents is true, complete and correct as of the date of the Application and
      (iv) Idea Financial may continuously rely on the information contained in the Application and you will notify us
      of any material changes to such information.<br><br>
      You understand and agree that Idea Financial and its agents and assignees are authorized to contact third parties
      to make inquiries in evaluating the Application, including, but not limited to, consumer or personal, business and
      investigative reports, and other information about you, including credit card processor statements and bank
      statements, from one or more consumer reporting agencies, such as TransUnion, Experian and Equifax, and from other
      credit bureaus, banks, creditors, or other third parties.<br><br>
      You understand and agree that the information provided within the Application may be provided to third parties to
      use the information for any lawful purpose, including for the purpose of offering credit and/or other products and
      services to the signing individual(s) and/or the company.<br><br>
      You further understand and agree that Idea Financial may transmit the Application, along with any of the foregoing
      information obtained in connection with the Application, to any or all of Idea Financial’s agents and assignees,
      including prospective agents and assignees, for the forgoing purposes.
    </span>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button type="button" mat-button color="primary" [mat-dialog-close]="true">OK</button>
</div>
