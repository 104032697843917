import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService, ApplicationOfferDisclosure } from 'common';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OfferService {
    protected readonly baseUrl: string;

    constructor(private http: HttpClient, configService: ConfigService) {
        this.baseUrl = `${configService.environment.applications.url}/api/offer`;
    }

    getDisclosures(applicationUuid: string, offerId: number): Observable<ApplicationOfferDisclosure[]> {
        return this.http.get<ApplicationOfferDisclosure[]>(`${this.baseUrl}/uuid/${applicationUuid}/${offerId}/disclosures`);
    }

}