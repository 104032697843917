import { ApplicationOfferBundleData, ProductCode } from "common";
import { OfferBundleData } from "../shared/offer-bundle.service";

export class OfferNavigator {
    offerBundles: OfferBundleData[];

    constructor(offerBundles: ApplicationOfferBundleData[], private currentOfferBundleId: number) {
        this.offerBundles = offerBundles
            .sort((a, b) => {
                if(a.expressCheckout && !b.expressCheckout)
                    return -1;
                else if(b.expressCheckout && !a.expressCheckout)
                    return 1;
                else
                    return b.offers[0]?.repaymentTerm - a.offers[0]?.repaymentTerm
            })
            .map(offerBundle => {
            return {
                repayment: offerBundle.offers[0]?.repaymentTerm?.toString(),
                bundleId: offerBundle.id,
                productCode: offerBundle.offers[0]?.productCode,
                amount: offerBundle.offers[0]?.amount,
                express: offerBundle.expressCheckout
            };
        });
    }

    getOfferBundlesByProductCode(productCode:ProductCode): OfferBundleData[] {
        return this.offerBundles?.filter(offerBundle => offerBundle.productCode == productCode)
    }

    get termOfferBundles(): OfferBundleData[] {
        return this.getOfferBundlesByProductCode(ProductCode.Term)
    }

    get locOfferBundles(): OfferBundleData[] {
        return this.getOfferBundlesByProductCode(ProductCode.LOC)
    }

    get lawOfferBundles(): OfferBundleData[] {
        return this.getOfferBundlesByProductCode(ProductCode.Law)
    }

    getBestOfferBundleByProductCode(productCode:ProductCode): OfferBundleData
    {
        let offerBundles = this.getOfferBundlesByProductCode(productCode);

        if (offerBundles) {
            return offerBundles[0];
        }
    }

    get bestTermOfferBundle(): OfferBundleData {
        return this.getBestOfferBundleByProductCode(ProductCode.Term);
    }

    get bestLocOfferBundle(): OfferBundleData {
        return this.getBestOfferBundleByProductCode(ProductCode.LOC);
    }

    get bestLawOfferBundle(): OfferBundleData {
        return this.getBestOfferBundleByProductCode(ProductCode.Law);
    }

    getBetterOfferBundle(offerBundle1: ApplicationOfferBundleData, offerBundle2: ApplicationOfferBundleData): ApplicationOfferBundleData {
        if (!offerBundle2) {
            return offerBundle1;
        }

        if (!offerBundle1) {
            return offerBundle2;
        }

        return offerBundle1.offers[0].amount > offerBundle2.offers[0].amount
            ? offerBundle1
            : offerBundle2;
    }

    get currentOfferBundle(): OfferBundleData {
        return this.offerBundles.find(x=>x.bundleId === this.currentOfferBundleId);
    }
}