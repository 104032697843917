<div class="disclosure-container">
	<p class="disclosure-header">
		OFFER SUMMARY - {{data.dbaOrName | uppercase}} TERM LOAN
    </p>

	<table class="disclosure-table">
		<colgroup>
			<col />
			<col />
			<col width="55%" />
		</colgroup>
		<tr>
			<td>
				Funding Provided
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="financedAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is how much funding {{data.dbaOrName}} will provide.
			</td>
		</tr>
		<tr *ngIf="data.totalPayoffAmount">
			<td>
				Funds Disbursed
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="disbursedAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the amount of funds disbursed directly to you.
			</td>
		</tr>
		<tr>
			<td>
				Total Payment Amount
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="paybackAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the total dollar amount of payments you will make during the term of the contract.
			</td>
		</tr>
		<tr>
			<td>
				Cost of Financing
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the dollar cost of your financing.
			</td>
		</tr>
		<tr>
			<td>
				Payment
			</td>
			<td>
				Manner: ACH
				<br />
                <ifa-disclosure-field [data]="data" field="paymentAmount" value="{{data.paymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
			</td>
			<td>
				This is the manner, frequency, and amount of each payment.
			</td>
		</tr>
		<tr>
			<td>
				Prepayment
			</td>
			<td colspan="2">
				You have the right to prepay the financing in whole at any time, without penalty, but do not have the right to prepay in part.
				For more information on prepayments, see section [3.3] of your financing contract.
			</td>
		</tr>
	</table>
</div>