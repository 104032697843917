<article class="container">
  <header>
    <div class="icon">
      <img src="../../../assets/bank-link-icon.png"
           alt="Link">
    </div>

    <ng-container *ngIf="bankLinkStatus === LinkingStatus.Linked; else linkBankTitle">
      <h1 class="heading heading--linked">
        <mat-icon class="heading__icon">check_circle</mat-icon>
        Your bank is already linked
      </h1>
    </ng-container>
    <ng-template #linkBankTitle>
      <h1 class="heading">
        Link your bank
      </h1>
    </ng-template>
  </header>

  <section class="content">
    <div class="content__text">
      <p>
        <ng-container *ngIf="bankLinkStatus === LinkingStatus.Linked; else linkBankDescription">
          We are currently assessing the health of your business and someone will reach out shortly.
        </ng-container>
        <ng-template #linkBankDescription>
          For faster access to funds, securely link and verify your bank below.
        </ng-template>
      </p>
      <p *ngIf="bankLinkStatus !== LinkingStatus.Linked">
        Once your bank is linked, the better and faster we can assess the health of your business.
        You'll need your bank login credentials which we can't see, store, or access.
      </p>
    </div>
    <ifa-link-your-bank [applicationUuid]="appUuid"
                        [bankLinkStatus]="bankLinkStatus"
                        [displayOnlyButton]="true">
    </ifa-link-your-bank>
  </section>
</article>
