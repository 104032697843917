<mat-accordion class="payment-schedule" ngClass.lt-md="payment-schedule-sm" ngClass.xs="payment-schedule-xs">
    <mat-expansion-panel class="mat-elevation-z0">
  
      <mat-expansion-panel-header>
        <mat-panel-title class="secondary-text">
          PAYMENT SCHEDULE
        </mat-panel-title>
      </mat-expansion-panel-header>
  
      <div fxLayout="column" class="ifc-card-elevation" ngClass.lt-md="payment-schedule-sm">
        <ifa-payment-schedule-table [offerDetail]="offer" [sampleDraw]="sampleDraw"></ifa-payment-schedule-table>
      </div>
  
    </mat-expansion-panel>
  </mat-accordion>
