import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferBundleData } from '../offer-bundle.service';

@Component({
  selector: 'ifa-repayment-navigator',
  templateUrl: './repayment-navigator.component.html',
  styleUrls: ['./repayment-navigator.component.scss']
})
export class RepaymentNavigatorComponent implements OnChanges {

  @Input() offerBundles: OfferBundleData[];
  @Input() currentOfferBundle: OfferBundleData;
  @Input() customerView: boolean;
  selectedBundle: string;

  constructor(private _router: Router, private _route: ActivatedRoute) { }

  click(offerBundle: OfferBundleData) {
    let path = offerBundle.productCode.toLowerCase();
    let pathMode = this.customerView ? 'preview' : 'calculator';

    let uuid = this._route.snapshot.queryParamMap.get('uuid');
    this._router.navigate([`/${pathMode}/${path}`], { queryParams: { uuid: uuid, bundleId: offerBundle.bundleId } });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.currentOfferBundle)
      this.selectedBundle = changes.currentOfferBundle?.currentValue?.bundleId;
  }



  isCurrentBundle(offerBundle: OfferBundleData): boolean {
    return offerBundle.bundleId === this.currentOfferBundle?.bundleId
      && offerBundle.productCode === this.currentOfferBundle.productCode
      && offerBundle.repayment === this.currentOfferBundle.repayment;
  }
}

