<div id="external-calculator" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center"
  fxLayoutGap.gt-xs="48px">

  <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="24px">

    <div>
      <span class="mat-title secondary-text">Repayment term</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px">
      <button mat-stroked-button [ngClass]="{'active-button': term === 9}" (click)="setTerm(9)">
        <span>9 MONTHS</span>
      </button>
      <button mat-stroked-button [ngClass]="{'active-button': term === 12}" (click)="setTerm(12)">
        <span>12 MONTHS</span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px">
      <button mat-stroked-button [ngClass]="{'active-button': term === 15}" (click)="setTerm(15)">
        <span>15 MONTHS</span>
      </button>
      <button mat-stroked-button [ngClass]="{'active-button': term === 18}" (click)="setTerm(18)">
        <span>18 MONTHS</span>
      </button>
    </div>

    <div class="layout padding-top">
      <span class="mat-title secondary-text">Payment frequency</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="24px">
      <button mat-stroked-button [ngClass]="{'active-button': paymentFrequency === PaymentFrequency.Weekly}"
        (click)="setPaymentFrequency(PaymentFrequency.Weekly)">
        <span>WEEKLY</span>
      </button>
      <button mat-stroked-button [ngClass]="{'active-button': paymentFrequency === PaymentFrequency.Monthly}"
        (click)="setPaymentFrequency(PaymentFrequency.Monthly)">
        <span>MONTHLY</span>
      </button>
    </div>

  </div>

  <mat-divider vertical class="vertical-divider" fxHide fxShow.gt-xs></mat-divider>

  <div class="ifa-calc-offer">

    <div id="loc-calculator" fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.lt-sm="center start"
      class="layout margin-top">

      <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="24px" fxFlex="75%">

        <div class="layout padding-top-md">
          <fieldset>
            <legend>
              Loan amount
            </legend>
            <input class="draw-amount" currencyMask type="text" [(ngModel)]="offerCalculator.sampleDraw" />
          </fieldset>
          <mat-slider min="5000" max="250000" step="1000" [(ngModel)]="offerCalculator.sampleDraw" thumbLabel="true"
            [displayWith]="onSampleAmountDisplay" (input)="onSampleAmountChanged($event)"></mat-slider>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="48px" fxFlexFill>
          <div fxLayout="column" class="layout padding-vertical-md">
            <div class="amount">{{offerCalculator.termPayment | currency:'$'}}</div>
            <div class="mat-caption"> Payment amount </div>
          </div>
          <div fxLayout="column" class="layout padding-vertical-md">
            <div class="amount">{{totalPayback | currency:'$'}}</div>
            <div class="mat-caption"> Total payback </div>
          </div>
        </div>
        
      </div>

    </div>

  </div>

</div>
