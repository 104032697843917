import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigService, initSentry } from 'common';
import { AppSettings } from './app.settings';
import { cacheSettings } from './environments/cache.settings';
import packageData from '../../../package.json';
import { LocalSettings } from './app/shared/local.settings';

const $env = '';
// const $env = '.test';
// const $env = '.staging';
// const $env = '.production';
// const $env = '.mobile';

fetch(`/assets/appsettings/appsettings${$env}.json`)
  .then((response) => response.json())
  .then((appsettings) => {
    if (environment.production) {
      enableProdMode()
    }

    ConfigService.environmentGlobal = appsettings;
    ConfigService.environmentGlobal.cache = cacheSettings;
    ConfigService.environmentGlobal.appVersion = packageData.version;
    LocalSettings.redirectionInformation = appsettings.applicationFormRedirect;

    initSentry();

    platformBrowserDynamic([{ provide: AppSettings, useValue: appsettings }])
      .bootstrapModule(AppModule)
      .then(() => {
        ConfigService.markConfigAsReady();
      })
      .catch((err) => console.error(err))
  })
