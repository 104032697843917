import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationOfferBundleData } from 'common';
import { OfferBundleService } from '../shared/offer-bundle.service';
import { ApplicationOfferDisclosure } from 'projects/common/src/public_api';
import { OfferService } from '../shared/offer.service';

@Injectable({
  providedIn: 'root'
})
export class OfferBundleResolver implements Resolve<ApplicationOfferBundleData> {

  constructor(
    private offerBundleService: OfferBundleService,
    private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationOfferBundleData> {
      return this.offerBundleService.getByApplicationUUID(route.queryParams.uuid, route.queryParams.bundleId);
  }
}

@Injectable({
  providedIn: 'root'
})
export class OfferDisclosureResolver implements Resolve<ApplicationOfferDisclosure[]> {

  constructor(
    private offerService: OfferService,
    private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationOfferDisclosure[]> {
      return this.offerService.getDisclosures(route.queryParams.uuid, route.queryParams.offerId);
  }
}
