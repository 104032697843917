import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LeadRoutingModule } from "./lead-routing.module";
import { SharedModule } from "../shared/shared.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LeadFlowStepperComponent } from "./lead-flow/lead-flow-stepper/lead-flow-stepper.component";
import { BusinessInformationComponent } from "./lead-flow/business-information/business-information.component";
import { OwnerInformationComponent } from "./lead-flow/owner-information/owner-information.component";
import { FinancialReviewComponent } from "./lead-flow/financial-review/financial-review.component";
import { LeadSubmittedComponent } from "./lead-flow/submitted/submitted.component";
import { UppyAngularDashboardModule } from "@uppy/angular";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LeadRoutingModule,
    FlexLayoutModule,
    UppyAngularDashboardModule,
  ],
  declarations: [
    LeadFlowStepperComponent,
    BusinessInformationComponent,
    OwnerInformationComponent,
    FinancialReviewComponent,
    LeadSubmittedComponent,
  ],
})
export class LeadModule {}
