import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent } from 'common';
import { AprCalculatorComponent } from './apr-calculator.component';

const routes: Routes = [
    {
        path: 'calculator',
        component: AppPageComponent,
        data: {
            appPage: {
                contentClass: 'simple',
                contentAlignment: 'center-start',
                contentWithoutPadding: true
            },
            appBar: {
              stickyDisabled: true
            }
        },
        children: [
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            },
            {
                path: 'apr',
                component: AprCalculatorComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AprCalculatorRoutingModule { }
