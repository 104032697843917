<div class="disclosure-container">
	<p class="disclosure-header">
		OFFER SUMMARY - {{data.dbaOrName | uppercase}} LAWSUIT COST FINANCING
    </p>

	<table class="disclosure-table">
		<colgroup>
			<col />
			<col />
			<col width="55%" />
		</colgroup>
		<tr>
			<td colspan="3">
				<i>
					The calculations below assume that you will make an initial draw of your full Approved Credit Limit of
                    <ifa-disclosure-field [data]="data" field="creditLimit" format="currency"></ifa-disclosure-field>,
					that you will choose to make minimum monthly payments, that you miss no payments, and that you do not re-draw on this line.
					Actual payments and costs may differ substantially.
				</i>
				<br />
				The calculations below also assume that there is no "Liquidity Event", as defined in Section 2.9(a) of your Master Agreement, during the Term of this financing.
				If there is a Liquidity Event, then repayment of all amounts owed is required within 60 days after the Liquidity Event.
				For more details on this repayment obligation, see Section 2.9(a) of your Master Agreement.
			</td>
		</tr>
		<tr>
			<td>
				Funding Provided
			</td>
			<td>
				<ifa-disclosure-field [data]="data" field="financedAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the maximum amount of funding that {{data.dbaOrName}} may provide.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Total Payment Amount
			</td>
			<td>
				<ifa-disclosure-field [data]="data" field="paybackAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the total dollar amount of payments you would make based on the assumptions described at the top of this disclosure.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Cost of Financing
			</td>
			<td>
				<ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the dollar cost of your financing based on the assumptions described at the top of this disclosure.
				The interest rate under your contract will adjust over time, so your actual cost of financing may vary.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Initial Payment
			</td>
			<td>
				Manner: ACH
                <div *ngIf="data.interestOnlyTerm">
                    Months 1-{{data.interestOnlyTerm}}:
                    <br />
                    <ifa-disclosure-field [data]="data" field="interestOnlyPaymentAmount" value="{{data.interestOnlyPaymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
                </div>
                <div>
                    Months {{data.interestOnlyTerm + 1}}-{{data.repaymentTerm}}:
                    <br />
                    <ifa-disclosure-field [data]="data" field="paymentAmount" value="{{data.paymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
                </div>
			</td>
			<td>
				The estimated periodic payment amounts disclosed are based on the assumptions described at the top of this disclosure.
			</td>
		</tr>
		<tr>
			<td>
				Prepayment
			</td>
			<td colspan="2">
				You have the right to prepay the financing in whole or in part at any time.
				There is no additional cost or discount for prepayment.
			</td>
		</tr>
	</table>
</div>