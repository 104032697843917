import { NgModule } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { CommonModule } from 'common';

import { AprCalculatorRoutingModule } from './apr-calculator-routing.module';
import { AprCalculatorComponent } from './apr-calculator.component';
import { AprDisclosureCaLocComponent } from './ca/loc/apr-disclosure-ca-loc.component';
import { AprDisclosureCaLcfComponent } from './ca/lcf/apr-disclosure-ca-lcf.component';
import { AprDisclosureCaTermComponent } from './ca/term/apr-disclosure-ca-term.component';
import { AprDisclosureUtLocComponent } from './ut/loc/apr-disclosure-ut-loc.component';
import { AprDisclosureUtLcfComponent } from './ut/lcf/apr-disclosure-ut-lcf.component';
import { AprDisclosureUtTermComponent } from './ut/term/apr-disclosure-ut-term.component';
import { DisclosureFieldComponent } from './disclosure-field/disclosure-field.component';

@NgModule({
  imports: [
    CommonModule,
    AprCalculatorRoutingModule
  ],
  declarations: [
    AprCalculatorComponent,
    AprDisclosureCaLocComponent,
    AprDisclosureCaLcfComponent,
    AprDisclosureCaTermComponent,
    AprDisclosureUtLocComponent,
    AprDisclosureUtLcfComponent,
    AprDisclosureUtTermComponent,
    DisclosureFieldComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class AprCalculatorModule { }
