import { Component, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppBarTitleService, ProductCode } from 'common';
import { BaseCalculatorComponent } from '../base/base-calculator.component';
import * as _ from 'lodash';
import { CalculatorService } from '../calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { OfferBundleService } from '../../shared/offer-bundle.service';
import { ApplicationService } from '../../shared/application.service';

@Component({
  selector: 'ifa-term-calculator',
  templateUrl: './term-calculator.component.html',
  styleUrls: ['./../base/base-calculator.component.scss', './term-calculator.component.scss']
})
export class TermCalculatorComponent extends BaseCalculatorComponent implements AfterContentInit {

  constructor(
    private route: ActivatedRoute,
    sanitizer: DomSanitizer,
    appBarTitleService: AppBarTitleService,
    calculatorService: CalculatorService,
    applicationService: ApplicationService,
    settings: AppSettings,
    percentPipe: PercentPipe,
    currencyPipe: CurrencyPipe,
    _offerBundleService: OfferBundleService
  ) {
    super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
  }

  ngAfterContentInit(): void {
    this._appBarTitleService.title = 'See all products';
    this._calculatorService.bundleChanged.subscribe(bundle => this.offer = _.first(bundle?.offers, { productCode: ProductCode.Term }));
  }
}
