import { NgModule } from '@angular/core';
import { CalculatorClosingRequirementsComponent } from './calculator-closing-requirements.component';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule, PipesModule } from 'common';

@NgModule({
    declarations: [
        CalculatorClosingRequirementsComponent
    ],
    imports: [
        FlexLayoutModule,
        BrowserModule,
        MaterialModule,
        PipesModule
    ],
    exports: [
        CalculatorClosingRequirementsComponent
    ]
})
export class CalculatorClosingRequirementsModule { }