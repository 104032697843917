import { NgModule } from '@angular/core';
import { CommonModule, NgxCurrencyModule } from 'common';
import { FocusModule } from './focus/focus.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgChartsModule } from 'ng2-charts';
import { LineOfCreditContentModule } from './line-of-credit-content/line-of-credit-content.module';
import { TermLoanContentModule } from './term-loan-content/term-loan-content.module';
import { LawCaseFinancingContentModule } from './law-case-financing-content/law-case-financing-content.module';
import { AddressComponent } from './address/address.component';
import { CreditApplicationTermsDialogComponent } from './credit-application-terms-dialog/credit-application-terms-dialog.component';
import { CarouselSliderModule } from './carousel-slider/carousel-slider.module';
import { ShareOfferModule } from './share-offer/share-offer.module';
import { RepaymentNavigatorModule } from './repayment-navigator/repayment-navigator.module';
import { ProductNavigatorModule } from './product-navigator/product-navigator.module';
import { CalculatorHeadingModule } from './calculator-heading/calculator-heading.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgxCurrencyModule.forRoot({
      align: "left",
      allowNegative: false,
      allowZero: true,
      decimal: ".",
      precision: 2,
      prefix: "$",
      suffix: "",
      thousands: ",",
      nullable: true
    }),
    NgChartsModule,
    FocusModule
  ],
  exports: [
    CommonModule,
    FocusModule,
    CarouselSliderModule,
    LineOfCreditContentModule,
    TermLoanContentModule,
    LawCaseFinancingContentModule,
    RepaymentNavigatorModule,
    ProductNavigatorModule,
    AddressComponent,
    CreditApplicationTermsDialogComponent,
    ShareOfferModule,
    CalculatorHeadingModule
  ],
  declarations: [
    AddressComponent,
    CreditApplicationTermsDialogComponent
  ]
})
export class SharedModule { }
