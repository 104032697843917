import { AfterContentInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppBarTitleService } from 'common';
import { BaseCalculatorComponent } from './base-calculator.component';
import { CalculatorService } from '../calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common'
import { ApplicationService } from '../../shared/application.service';

@Directive()
export abstract class BaseLocCalculatorComponent extends BaseCalculatorComponent implements AfterContentInit {

    constructor(
        route: ActivatedRoute,
        sanitizer: DomSanitizer,
        appBarTitleService: AppBarTitleService,
        calculatorService: CalculatorService,
        applicationService: ApplicationService,
        settings: AppSettings,
        percentPipe: PercentPipe,
        currencyPipe: CurrencyPipe
    ) {
        super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
    }

    protected getSampleDraw(): number { return 0; }

    protected getTermPayment(): number { return 0; }

    protected getTotalInterest(term: number): number { return 0; }
}
