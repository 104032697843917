import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalculatorPaymentScheduleComponent } from './calculator-payment-schedule.component';
import { PaymentScheduleTableModule } from '../payment-schedule-table/payment-schedule-table.module';
import { MaterialModule } from 'common';

@NgModule({
    declarations: [
        CalculatorPaymentScheduleComponent
    ],
    imports: [
        MatIconModule,
        FlexLayoutModule,
        CommonModule,
        PaymentScheduleTableModule,
        FlexLayoutModule,
        MaterialModule
    ],
    exports: [
        CalculatorPaymentScheduleComponent
    ]
})
export class CalculatorPaymentScheduleModule { }