import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService } from 'common';
import { DomSanitizer } from '@angular/platform-browser';
import { CalculatorService } from '../../calculator/calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationService } from '../application.service';
import { BaseCalculatorComponent } from '../../calculator/base/base-calculator.component';

@Component({
  selector: 'ifa-line-of-credit-content',
  templateUrl: './line-of-credit-content.component.html',
  styleUrls: ['./line-of-credit-content.component.scss']
})
export class LineOfCreditContentComponent extends BaseCalculatorComponent {

  @Input() customerView: boolean

  constructor(
    route: ActivatedRoute,
    sanitizer: DomSanitizer,
    appBarTitleService: AppBarTitleService,
    calculatorService: CalculatorService,
    applicationService: ApplicationService,
    settings: AppSettings,
    percentPipe: PercentPipe,
    currencyPipe: CurrencyPipe
  ) {
    super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
  }
}
