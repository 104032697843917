<div class="disclosure-container">
    <p class="disclosure-header">
        OFFER SUMMARY - {{data.dbaOrName | uppercase}} LAWSUIT COST FINANCING
    </p>
  
    <table class="disclosure-table">
        <colgroup>
            <col />
            <col />
            <col width="55%" />
        </colgroup>
        <tr>
            <td colspan="3">
                <i>
                    The calculations below are based on an initial draw of your full Approved Credit Limit of
                    <ifa-disclosure-field [data]="data" field="creditLimit" format="currency"></ifa-disclosure-field>
                    and assume that you will choose to make minimum payments, that you miss no payments, and that you do not re-draw on this line.
                    Actual costs may differ substantially.
                </i>
                <br />
                <br />
                The calculations below also assume that there is no "Liquidity Event", as defined in Section 2.9(a) of your Master Agreement, during the Term of this financing.
                If there is a Liquidity Event, then repayment of all amounts owed is required within 60 days after the Liquidity Event.
                For more details on this repayment obligation, see Section 2.9(a) of your Master Agreement.
            </td>
        </tr>
        <tr>
            <td>Funding Provided</td>
            <td>
                <ifa-disclosure-field [data]="data" field="financedAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is the maximum amount of funding {{data.dbaOrName}} may provide.
            </td>
        </tr>
        <tr>
            <td>Initial Annual Percentage Rate (APR)</td>
            <td>
                <ifa-disclosure-field [data]="data" field="apr" format="percent:'1.2-2'"></ifa-disclosure-field>
            </td>
            <td>
                APR is the cost of your financing expressed as a yearly rate.
                APR includes the amount and timing of the funding you receive, interest and fees you pay and the payments you make.
                <br />
                <br />
                APR is not an interest rate.
                Your initial interest rate is
                <ifa-disclosure-field [data]="data" field="air" format="percent:'1.2-2'"></ifa-disclosure-field>.
                Although your interest rate will adjust over time, for the purposes of calculating this APR estimate, we have used the initial interest rate for future periods where the interest rate is not preset by the contract.
                Your APR may be higher than your interest rate because APR incorporates interest costs and other finance charges.
            </td>
        </tr>
        <tr>
            <td>Estimated Finance Charge</td>
            <td>
                <ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is the dollar cost of our financing based upon the assumptions described at the top of this disclosure.
                The interest rate under your contract will adjust over time, so your actual finance charge may vary.
            </td>
        </tr>
        <tr>
            <td>Estimated Total Payments</td>
            <td>
                <ifa-disclosure-field [data]="data" field="paybackAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is the total dollar amount of payments you will make during the term of the contract based upon the assumptions described at the top of this disclosure.
            </td>
        </tr>
        <tr *ngIf="data.paymentFrequency !== 'monthly'">
            <td>
                Average Monthly Cost
            </td>
            <td>
                <ifa-disclosure-field [data]="data" field="averageMonthlyPaymentAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                Although this financing does not have monthly payments, this is our calculation of your average monthly cost for comparison purposes.
            </td>
        </tr>
        <tr>
            <td>Estimated Payment</td>
            <td>
                <div *ngIf="data.interestOnlyTerm">
                    <ifa-disclosure-field [data]="data" field="interestOnlyPaymentAmount" value="Months 1-{{data.interestOnlyTerm}}: {{data.interestOnlyPaymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
                </div>
                <div>
                    <ifa-disclosure-field [data]="data" field="paymentAmount" value="Months {{data.interestOnlyTerm + 1}}-{{data.repaymentTerm}}: {{data.paymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
                </div>
            </td>
            <td>
                The periodic payment amounts disclosed are based upon the initial interest rate, and the actual rate may change over time.
            </td>
        </tr>
        <tr>
            <td>Draw Period</td>
            <td>
                <ifa-disclosure-field [data]="data" field="drawTerm" value="{{data.drawTerm}} months"></ifa-disclosure-field>
            </td>
            <td>
                The draw period is the length of time during which you may make draws.
            </td>
        </tr>
        <tr>
            <td>Term</td>
            <td>
                <ifa-disclosure-field [data]="data" field="repaymentTermYearsMonths"></ifa-disclosure-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>Prepayment</td>
            <td colspan="2">
                <span>
                    If you pay off the financing early you will need to pay all or portion of the finance charge, up to
                    <ifa-disclosure-field [data]="data" field="prepaidFinanceChargesAmount" format="currency"></ifa-disclosure-field>.
                </span>
                <span>
                    If you pay off the financing early you will not pay additional fees.
                </span>
            </td>
        </tr>
    </table>
</div>