import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCode } from 'common';
import { OfferNavigator } from '../../calculator/offer-navigator';
import { OfferBundleData } from '../offer-bundle.service';

@Component({
  selector: 'ifa-product-navigator',
  templateUrl: './product-navigator.component.html',
  styleUrls: ['./product-navigator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductNavigatorComponent {

  @Input() offerNavigator: OfferNavigator;
  @Input() customerView: boolean;

  constructor(private _router: Router, private _route: ActivatedRoute) { }

  get locOfferBundle(): OfferBundleData {
    return this.offerNavigator.bestLocOfferBundle;
  }

  get termOfferBundle(): OfferBundleData {
    return this.offerNavigator.bestTermOfferBundle;
  }

  get lawOfferBundle(): OfferBundleData {
    return this.offerNavigator.bestLawOfferBundle;
  }

  get currentProduct(): ProductCode {
    return this.offerNavigator.currentOfferBundle.productCode;
  }

  get isCurrentTermProduct():boolean {
    return this.currentProduct === ProductCode.Term;
  }

  get isCurrentLocProduct():boolean {
    return this.currentProduct === ProductCode.LOC;
  }

  get isCurrentLawProduct():boolean {
    return this.currentProduct === ProductCode.Law;
  }

  click(offerBundle: OfferBundleData) {
    let path = offerBundle.productCode.toLowerCase();

    let uuid = this._route.snapshot.queryParamMap.get('uuid');

    let pathMode = this.customerView ? 'preview' : 'calculator';

    this._router.navigate([`/${pathMode}/${path}`], { queryParams: { uuid: uuid, bundleId: offerBundle.bundleId } });
  }
}
