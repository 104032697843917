<ifc-app-card [asideOrderLtLg]="-1" asideOrderGtMd="-1" fxLayout.gt-sm="row">

  <form [formGroup]="form">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">

      <div formGroupName="base" fxLayout="column" fxLayout.lt-md="column"  fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>First name</mat-label>
          <input matInput formControlName="firstName" type="text" required maxlength="40" #firstName autocomplete="given-name">
        </mat-form-field>
      </div>

      <div formGroupName="base" fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>Last name</mat-label>
          <input matInput formControlName="lastName" type="text" required maxlength="80" autocomplete="family-name">
        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column"  fxLayoutAlign="start start" fxLayoutGap="12px">

      <div formGroupName="base"  fxLayout.lt-sm="column" fxLayout="column" fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" required type="email" (blur)="emailChanged($event.target.value)" autocomplete="email">
        </mat-form-field>
      </div>

      <div fxLayout="column"  fxLayout.lt-sm="column"  fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>Referral code</mat-label>
          <input matInput formControlName="referralCode" type="text" minlength="7" maxlength="10" autocomplete="off">
        </mat-form-field>
        <div class="help-text">
          If you were invited by a friend, enter your friend’s referral code.
          <span *ngIf="form.get('referralCode').errors">The code is between 7-10 characters long and can only be numbers and letters.</span>
        </div>
      </div>

    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
      <div formGroupName="base" fxLayout="column" fxLayout.lt-sm="column"  fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>Company name (legal entity name)</mat-label>
          <input matInput formControlName="legalEntityName" required maxlength="255" type="text" autocomplete="organization">
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline">
          <mat-label>EIN/Tax ID</mat-label>
          <input matInput formControlName="taxId" type="text" customMask="00-0000000" inputmode="numeric" maxlength="10" minlength="9" autocomplete="off">
        </mat-form-field>
        <div class="help-text">
          The EIN can be found on your business tax returns, IRS notices, bank statements or
          online.
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start start">
      <span class="mat-body-1 secondary-text">Business address</span>
    </div>

    <ifa-address [form]="form.controls.address"></ifa-address>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
      <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-label>Primary phone number</mat-label>
        <input matInput formControlName="phone" type="text" customMask="(000) 000-0000" inputmode="numeric" autocomplete="tel-national" maxlength="14" minlength="10">
      </mat-form-field>

      <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-label>Average monthly sales</mat-label>
        <input matInput formControlName="averageMonthlySales" type="text"  inputmode="numeric" currencyMask [options]="{ precision: '0'}" autocomplete="off">
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
      <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-label>Time in business</mat-label>
        <mat-select matInput formControlName="timeInBusiness" autocomplete="off">
          <mat-option value="0"> Less than 1 </mat-option>
          <mat-option value="1"> 1 </mat-option>
          <mat-option value="2"> 2 </mat-option>
          <mat-option value="3"> 3 </mat-option>
          <mat-option value="4"> 4 </mat-option>
          <mat-option value="5"> 5 or more </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
        <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
          <mat-label>Entity type</mat-label>
          <mat-select matInput formControlName="entityType" autocomplete="off">
            <mat-option value="Corporation"> Corporation </mat-option>
            <mat-option value="General Partnership"> General Partnership </mat-option>
            <mat-option value="Limited Liability Company"> Limited Liability Company </mat-option>
            <mat-option value="Limited Partnership"> Limited Partnership </mat-option>
            <mat-option value="Sole Proprietorship"> Sole Proprietorship </mat-option>
            <mat-option value="Not For Profit"> Not For Profit </mat-option>
            <mat-option value="Public"> Public </mat-option>
            <mat-option value="Legal Partnership"> Legal Partnership </mat-option>
            <mat-option value="Other"> Other </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="help-text">
          Your business's entity type can be found in your business registration or tax documents.
        </div>
      </div>
    </div>

    <div fxLayout="row" fxFlexAlign="space-between center" fxFlexFill>
      <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
        <button mat-raised-button color="primary" type="button" matStepperNext>
          Continue
        </button>
      </div>
    </div>

  </form>

  <aside>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
      <h3 class="mat-headline">Hello, get started by telling us about your business</h3>
      <mat-list>
        <mat-list-item>
          <span class="item-title">
            <mat-icon class="mat-green">thumb_up</mat-icon>
          </span>
          <p class="item-content"> Apply and get funded the same day</p>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title">
            <mat-icon class="mat-green">thumb_up</mat-icon>
          </span>
          <p class="item-content"> Up to $250,000 in funding</p>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title">
            <mat-icon class="mat-green">thumb_up</mat-icon>
          </span>
          <p class="item-content"> Won't impact your credit score</p>
        </mat-list-item>
      </mat-list>
      <ifc-ssl-info></ifc-ssl-info>
    </div>
  </aside>

</ifc-app-card>
