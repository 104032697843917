export class AppSettings {
    rootSite: {
        url: string
    }
    applications: {
        url: string
    }
    leads: {
        url: string
    }
    brokers: {
        url: string
    }
    advisors: {
        url: string
    }
    doc: {
        url: string
    }
    companies: {
        url: string;
    }
    offerBundle: {
        url: string
    }
    banking: {
        url: string;
    };
    redirection: {
        allowed: boolean,
        url: string
    }
}