<div class="disclosure-container">
    <p class="disclosure-header">
        OFFER SUMMARY - {{data.dbaOrName | uppercase}} TERM LOAN
    </p>
  
    <table class="disclosure-table">
        <colgroup>
            <col />
            <col />
            <col width="55%" />
        </colgroup>
        <tr>
            <td>Funding Provided</td>
            <td>
                <ifa-disclosure-field [data]="data" field="creditLimit" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is how much funding {{data.dbaOrName}} will provide.
            </td>
        </tr>
        <tr>
            <td>Annual Percentage Rate (APR)</td>
            <td>
                <ifa-disclosure-field [data]="data" field="apr" format="percent:'1.2-2'"></ifa-disclosure-field>
            </td>
            <td>
                APR is the cost of your financing expressed as a yearly rate.
                APR includes the amount and timing of the funding you receive, fees you pay and the payments you make.
                <br />
                <br />
                Your APR is not an interest rate.
                The cost of this financing is based upon fees charged rather than interest that accrues over time.
            </td>
        </tr>
        <tr>
            <td>Finance Charge</td>
            <td>
                <ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is the dollar cost of your financing.
            </td>
        </tr>
        <tr>
            <td>Total Payment Amount</td>
            <td>
                <ifa-disclosure-field [data]="data" field="paybackAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                This is the total dollar amount of payments you will make during the term of the contract.
            </td>
        </tr>
        <tr *ngIf="data.paymentFrequency !== 'monthly'">
            <td>
                Average Monthly Cost
            </td>
            <td>
                <ifa-disclosure-field [data]="data" field="averageMonthlyPaymentAmount" format="currency"></ifa-disclosure-field>
            </td>
            <td>
                Although this financing does not have monthly payments, this is our calculation of your average monthly cost for comparison purposes.
            </td>
        </tr>
        <tr>
            <td>Payment</td>
            <td>
                <ifa-disclosure-field [data]="data" field="paymentAmount" value="{{data.paymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
            </td>
            <td>
                Your payment will be due every {{data.termName}}.
            </td>
        </tr>
        <tr>
            <td>Term</td>
            <td>
                <ifa-disclosure-field [data]="data" field="repaymentTermYearsMonths"></ifa-disclosure-field>
            </td>
            <td></td>
        </tr>
        <tr>
            <td>Prepayment</td>
            <td colspan="2">
                <span>
                    If you pay off the financing early you will still need to pay all or portion of the finance charge, up to
                    <ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>.
                </span>
                <span>
                    If you pay off the financing early you will not pay additional fees.
                </span>
            </td>
        </tr>
    </table>
  </div>