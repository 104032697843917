import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CalculatorService } from '../../calculator.service';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationOfferBundleData, FeeType, OfferType, ProductCode } from 'common';

@Component({
  selector: 'ifa-summary-offer-widget',
  templateUrl: './summary-offer-widget.component.html',
  styleUrls: ['./summary-offer-widget.component.scss']
})
export class SummaryOfferWidgetComponent {

  @Input() bundle: ApplicationOfferBundleData;
  @Input() uuid: string;

  offerType = OfferType

  constructor(private _router: Router,
    private _calculatorService: CalculatorService,
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    private _route: ActivatedRoute
  ) { }

  get factorRate(): number {
    return this.bundle.offers[0].interestRate / 100 + 1;
  }

  get type(): OfferType {
    if (this.bundle.offers[0]?.productCode === ProductCode.LOC) {
      return OfferType.LOC
    } else if (this.bundle.offers[0]?.productCode === ProductCode.Term) {
      return OfferType.Term;
    } else if (this.bundle.offers[0]?.productCode === ProductCode.Law) {
      return OfferType.Law;
    }
  }

  get drawFee(): string {
    return this.formatFee(this.bundle.offers[0].drawDownFee, this.bundle.offers[0].drawDownFeeType);
  }

  private formatFee(fee?: number, feeType?: FeeType): string {
    return feeType === FeeType.Percentage ? this.percentPipe.transform(fee / 100, '1.2-2') : this.currencyPipe.transform(fee, 'USD', 'symbol', '1.2-2');
  }

  get totalPayback(): number {
    const totalInterest = this._calculatorService.getTermLoanTotalInterest(this.bundle.offers[0], this.bundle.offers[0].repaymentTerm);
    const totalPrincipal = this.bundle.offers[0].amount;
    const totalPayback = totalPrincipal + totalInterest;
    return totalPayback;
  }

  get dailyInterestRate(): number {
    return this._calculatorService.getDailyInterestRate(this.bundle.offers[0].interestRate / 100);
  }

  getAmountTitle(): string {
    switch (this.type) {
      case OfferType.Term:
        return 'Loan amount';
      case OfferType.LOC:
      case OfferType.Law:
        return 'Credit limit';
    }
  }

  getAmount() {
    let amount = 0;
    this.bundle.offers.forEach(item => {
      amount += item.amount;
    });
    return amount;
  }

  detailLink(): void {
    let path: string;
    switch (this.type) {
      case OfferType.LOC:
        path = 'loc';
        break;
      case OfferType.Term:
        path = 'term';
        break;
      case OfferType.Law:
        path = 'law';
        break;
    }
    this._route.parent.url.subscribe(url => {
      this._router.navigate([`/${url[0].path}/${path}`], { queryParams: { uuid: this.uuid, bundleId: this.bundle?.id } });
    })
  }

}
