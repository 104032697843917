import { CurrencyPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationBaseData, ApplicationOffer, BrandingService, ProductCode, ProductCodeLabel } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifa-send-offer-email',
  templateUrl: './send-offer-email.component.html',
  styleUrls: ['./send-offer-email.component.scss']
})
export class SendOfferEmailComponent implements OnInit {
  form: UntypedFormGroup;

  companyName: string;
  emailBody: string;

  constructor(
    public dialogRef: MatDialogRef<SendOfferEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private currencyPipe: CurrencyPipe,
    private brandingService: BrandingService
  ) {
    if (this.data.application?.customerEmails) {
      this.form = this.formBuilder.group({
        email: this.data.application?.customerEmails?.join('; ') || ''
      });
    }
  }

  ngOnInit(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe(companyData => {
        this.companyName = companyData.dba;

        if (this.data?.emailBody) {
          this.emailBody = this.data?.emailBody;
        }
        else {
          this.emailBody =
            `Congratulations! ${this.data?.application?.businessName} has been approved for a ${this.productCodeLabel(this.data?.offer?.productCode)}` +
            ` of ${this.currencyPipe.transform(this.data?.offer?.amount, 'USD', 'symbol', '1.2-2')} from ${this.companyName}. See the offer details here:%0D%0A${encodeURIComponent(this.data?.shareUrl)}`;
        }
      });
  }

  productCodeLabel(code: ProductCode): string {
    return ProductCodeLabel.get(code);
  }

  static show(dialog: MatDialog, application: ApplicationBaseData, shareUrl: string, offer: ApplicationOffer, emailBody: string) {
    dialog.open(SendOfferEmailComponent, {
      width: '560px',
      data: {
        application,
        shareUrl,
        offer,
        emailBody
      },
      closeOnNavigation: true,
    })
  }

}
