import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LeadModule } from "./lead/lead.module";
import { SharedModule } from "./shared/shared.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppLoaderInterceptor, CoreModule, ErrorsModule } from "common";
import { CalculatorModule } from "./calculator/calculator.module";
import { BankLinkingModule } from "./bank-linking/bank-linking.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LeadModule,
    CalculatorModule,
    BankLinkingModule,
    SharedModule,
    CoreModule,
    ErrorsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppLoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
