<mat-card>
  <mat-card-header>
    <mat-card-title>
      APR Calculator
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="apr-input-form">
      <mat-form-field appearance="outline">
        <mat-label>Credit Limit, $</mat-label>
        <input matInput type="number" placeholder="Example: 100000" name="creditLimit" [(ngModel)]="creditLimit">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Annual interest rate or Factor rate</mat-label>
        <input matInput type="number" placeholder="Example: 23.99" name="interestRate" [(ngModel)]="interestRate">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Payment Frequency</mat-label>
        <mat-select name="paymentFrequency" [(ngModel)]="paymentFrequency">
          <mat-option value="weekly">Weekly</mat-option>
          <mat-option value="bi-weekly">Bi-weekly</mat-option>
          <mat-option value="monthly">Monthly</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Draw Term, months</mat-label>
        <input matInput type="number" placeholder="Example: 12" name="drawTerm" [(ngModel)]="drawTerm">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Repayment Term, months</mat-label>
        <input matInput type="number" placeholder="Example: 18" name="repaymentTerm" [(ngModel)]="repaymentTerm">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Interest Only Term, months</mat-label>
        <input matInput type="number" placeholder="Example: 0" name="interestOnlyTerm" [(ngModel)]="interestOnlyTerm">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Draw Fee, %</mat-label>
        <input matInput type="number" placeholder="Example: 2.49" name="drawFee" [(ngModel)]="drawFee">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Renewal Fee, $</mat-label>
        <input matInput type="number" placeholder="Example: $500" name="renewalFee" [(ngModel)]="renewalFee">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Other Deferred Fees, $</mat-label>
        <input matInput type="number" placeholder="Example: $0" name="otherDeferredFee" [(ngModel)]="otherDeferredFee">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Other Cash Fees, $</mat-label>
        <input matInput type="number" placeholder="Example: $0" name="otherCashFee" [(ngModel)]="otherCashFee">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Balance Transfer, $</mat-label>
        <input matInput type="number" placeholder="Example: $50,000" name="balanceTransfer" [(ngModel)]="balanceTransfer">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>3rd Party Payoff, $</mat-label>
        <input matInput type="number" placeholder="Example: $50,000" name="payoff" [(ngModel)]="payoff">
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<div class="disclosure-container" *ngIf="!allDataIn">
  <p class="disclosure-header">
    Please enter all data above
  </p>
</div>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" *ngIf="allDataIn">
  <mat-tab label="CA, LOC">
    <ifa-apr-disclosure-ca-loc [data]="loc"></ifa-apr-disclosure-ca-loc>
  </mat-tab>

  <mat-tab label="CA, Term">
    <ifa-apr-disclosure-ca-term [data]="term"></ifa-apr-disclosure-ca-term>
  </mat-tab>

  <mat-tab label="CA, LevelEsq">
    <ifa-apr-disclosure-ca-lcf [data]="lcf"></ifa-apr-disclosure-ca-lcf>
  </mat-tab>

  <mat-tab label="UT, LOC">
    <ifa-apr-disclosure-ut-loc [data]="loc"></ifa-apr-disclosure-ut-loc>
  </mat-tab>

  <mat-tab label="UT, Term">
    <ifa-apr-disclosure-ut-term [data]="term"></ifa-apr-disclosure-ut-term>
  </mat-tab>

  <mat-tab label="UT, LevelEsq">
    <ifa-apr-disclosure-ut-lcf [data]="lcf"></ifa-apr-disclosure-ut-lcf>
  </mat-tab>
</mat-tab-group>
