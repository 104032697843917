import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent, AppBarCloseAction, AppBarTitleComponent } from 'common';
import { LocCalculatorComponent } from './loc/loc-calculator.component';
import { TermCalculatorComponent } from './term/term-calculator.component';
import { BundleCalculatorComponent } from './bundle/bundle-calculator.component';
import { SummaryCalculatorComponent } from './summary/summary-calculator.component';
import { LawCalculatorComponent } from './law/law-calculator.component';
import { LineOfCreditExternalCalculatorComponent } from '../shared/line-of-credit-content/external-calculator/line-of-credit-external-calculator.component';
import { OfferBundleResolver, OfferDisclosureResolver } from './calculator.resolver';
import { CalculatorDisclosuresComponent } from './disclosures/calculator-disclosures.component';

const customerPreview = {
    preview: true
};

const routes: Routes = [
    {
        path: 'calculator',
        component: AppPageComponent,
        data: {
            appPage: {
                contentClass: 'simple',
                contentAlignment: 'center-start',
                contentWithoutPadding: true
            },
            appBar: {
              stickyDisabled: true
            }
        },
        children: [
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            },
            {
                path: 'summary',
                component: SummaryCalculatorComponent,
                data: {
                    preview: false
                }
            }
        ]
    },
    {
        path: 'calculator',
        component: AppPageComponent,
        children: [
            
            {
                path: 'bundle',
                component: BundleCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                }
            },
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            }
        ],
        data: {
            leadFlowStepContainer: false,
            appBar: {
                closeAction: AppBarCloseAction.HOME,
                expandable: false,
                logoHidden: true,
                stickyDisabled: true,
                contactInfoClass: 'mt-auto'
            },
            appPage: { contentClass: 'simple' }
        }
    },
    {
        path: 'calculator',
        component: AppPageComponent,
        children: [
            {
                path: 'law',
                component: LawCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'loc',
                component: LocCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'term',
                component: TermCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            }
        ],
        data: {
            leadFlowStepContainer: false,
            appBar: {
                closeAction: AppBarCloseAction.CALCULATOR_SUMMARY,
                expandable: false,
                logoHidden: true,
                stickyDisabled: true,
                contactInfoClass: 'mt-auto'
            },
            appPage: { contentClass: 'simple' }
        }
    },
    {
        path: 'preview',
        component: AppPageComponent,
        children: [
            {
                path: 'summary',
                component: SummaryCalculatorComponent,
                data: customerPreview
            },
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            }
        ],
        data: {
            leadFlowStepContainer: false,
            appBar: {
                expandable: false,
                logoHidden: true,
                stickyDisabled: true,
                contactInfoClass: 'hidden'
            },
            appPage: { contentClass: 'simple' }
        }
    },
    {
        path: 'preview',
        component: AppPageComponent,
        children: [
            {
                path: 'loc',
                component: LocCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                data: customerPreview,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'term',
                component: TermCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                data: customerPreview,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: 'law',
                component: LawCalculatorComponent,
                resolve: {
                    bundle: OfferBundleResolver
                },
                data: customerPreview,
                runGuardsAndResolvers: 'paramsOrQueryParamsChange'
            },
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'title',
                        component: AppBarTitleComponent,
                    },
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            }
        ],
        data: {
            leadFlowStepContainer: false,
            appBar: {
                closeAction: AppBarCloseAction.CALCULATOR_SUMMARY,
                expandable: false,
                logoHidden: true,
                stickyDisabled: true,
                contactInfoClass: 'hidden'
            },
            appPage: { contentClass: 'simple' }
        }
    },
    {
        path: 'external-calculator/loc',
        component: LineOfCreditExternalCalculatorComponent,
        resolve: {
            bundle: OfferBundleResolver
        }
    },
    {
        path: 'calculator/disclosures',
        component: CalculatorDisclosuresComponent,
        resolve: {
            disclosures: OfferDisclosureResolver
        }
    },
    {
        path: 'preview/disclosures',
        component: CalculatorDisclosuresComponent,
        resolve: {
            disclosures: OfferDisclosureResolver
        }
    },
    {
        path: 'calculator',
        component: AppPageComponent,
        data: {
            appPage: {
                contentClass: 'simple',
                contentAlignment: 'center-start',
                contentWithoutPadding: true
            },
            appBar: {
              stickyDisabled: true
            }
        },
        children: [
            {
                path: '',
                outlet: 'app-bar',
                component: AppBarComponent,
                children: [
                    {
                        path: '',
                        outlet: 'right',
                        component: AppBarContactComponent
                    }
                ]
            },
            {
                path: '',
                outlet: 'app-footer',
                component: AppFooterComponent,
                data: {
                    appFooter: {
                        full: true
                    }
                }
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CalculatorRoutingModule { }
