import { Component, Input } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { ProductCode } from 'common';
import { OfferCalculator } from '../../calculator/offer-calculator';

@Component({
  selector: 'ifa-calculator-draw-slider',
  templateUrl: './calculator-draw-slider.component.html',
  styleUrls: ['./calculator-draw-slider.component.scss']
})
export class CalculatorDrawSliderComponent {
  @Input() offerCalculator : OfferCalculator
  @Input() productCode: ProductCode

  get theme(): string {
    switch(this.productCode) {
      case ProductCode.LOC: return "blue";
      case ProductCode.Law: return "gray";
    }
  }

  onSampleDrawChanged(event: any): void {
    this.offerCalculator.sampleDraw = event.value;
  }

  onSampleDrawDisplay(value: number): string {
    return Math.round(value / 1000).toString() + 'K';
  }

  onSampleDurationDisplay(value: number): string {
    return (value + 1).toString();
  }

  onSampleDurationChanged(event: MatSliderChange): void {
    this.offerCalculator.sampleDurationIndex = event.value;
  }

  get sampleDurationLength() {
    return this.offerCalculator.cards.length - 1;
  }
}
