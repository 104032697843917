import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { CalculatorDetailsTableComponent } from './calculator-details-table.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
  ],
  declarations: [
    CalculatorDetailsTableComponent
  ],
  exports: [
    CalculatorDetailsTableComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class CalculatorDetailsTableModule { }
