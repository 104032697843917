<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px" class="layout padding-horizontal">
  <div class="layout padding-horizontal">
    <span class="mat-headline">Upload PDF copies of the last 6 months of your <br> businesses bank statements
      below</span>
  </div>

  <div class="layout padding-top">
    <uppy-dashboard [uppy]="uppy" [props]="uppyDashboardOptions"></uppy-dashboard>
  </div>

  <div class="layout padding-top" fxLayout="column" fxLayoutAlign="end end">
    <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
      <button mat-button type="button" (click)="goBack()">
        Back
      </button>
      <button mat-raised-button color="primary" type="button" (click)="submit()">
        Submit application
      </button>
    </div>
  </div>

  <ifc-ssl-info fxLayout="row" fxLayoutAlign="center strech"></ifc-ssl-info>
</div>
