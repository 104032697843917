import { Component, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppBarTitleService, ApplicationOffer, ProductCode } from 'common';
import { BaseCalculatorComponent } from '../base/base-calculator.component';
import * as _ from 'lodash';
import { CalculatorService } from '../calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationService } from '../../shared/application.service';

@Component({
    selector: 'ifa-bundle-calculator',
    templateUrl: './bundle-calculator.component.html',
    styleUrls: ['./../base/base-calculator.component.scss', './bundle-calculator.component.scss']
})
export class BundleCalculatorComponent extends BaseCalculatorComponent implements AfterContentInit {

    locOffer: ApplicationOffer = null;
    termOffer: ApplicationOffer = null;
    lawOffer: ApplicationOffer = null;

    constructor(
        route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private appBarTitleService: AppBarTitleService,
        private calculatorService: CalculatorService,
        applicationService: ApplicationService,
        settings: AppSettings,
        percentPipe: PercentPipe,
        currencyPipe: CurrencyPipe
    ) {
        super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
    }

    ngAfterContentInit(): void {
        this._calculatorService.bundleChanged.subscribe(bundle => {
            this.locOffer = _.find(bundle?.offers, { productCode: ProductCode.LOC });
            this.termOffer = _.find(bundle?.offers, { productCode: ProductCode.Term });
            this.lawOffer = _.find(bundle?.offers, { productCode: ProductCode.Law });
        });
    }


    protected getSampleDraw(): number { return 0; }

    protected getTermPayment(): number { return 0; }

    protected getTotalInterest(term: number): number { return 0; }
}
