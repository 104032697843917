import { Component, Input } from '@angular/core';
import { LocDisclosureInfo } from '../../apr-calculator.model';

@Component({
    selector: 'ifa-apr-disclosure-ut-loc',
    templateUrl: './apr-disclosure-ut-loc.component.html',
    styleUrls: ['../../apr-disclosure.component.scss', './apr-disclosure-ut-loc.component.scss']
})
export class AprDisclosureUtLocComponent {

    @Input() data: LocDisclosureInfo = new LocDisclosureInfo('', 0, 0, null, null, 0, 0, 0, 0);
}