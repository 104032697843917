import { Component, Input } from '@angular/core';
import { TermDisclosureInfo } from '../../apr-calculator.model';

@Component({
    selector: 'ifa-apr-disclosure-ut-term',
    templateUrl: './apr-disclosure-ut-term.component.html',
    styleUrls: ['../../apr-disclosure.component.scss', './apr-disclosure-ut-term.component.scss']
})
export class AprDisclosureUtTermComponent {

    @Input() data: TermDisclosureInfo = new TermDisclosureInfo('', 0, 0, null, null, 0);
}