import { Component, Input } from "@angular/core";
import { LinkingStatus, MessageService } from "common";
import { AppSettings } from "projects/application/src/app.settings";

declare global {
  interface Window {
    Plaid: any;
  }
}

@Component({
  selector: "ifa-link-your-bank",
  templateUrl: "./link-your-bank.component.html",
  styleUrls: ["./link-your-bank.component.css"],
})
export class LinkYourBankComponent {
  get BankLinkStatus() {
    return LinkingStatus;
  }

  @Input() applicationUuid: string;
  @Input() bankLinkStatus: LinkingStatus;
  @Input() displayOnlyButton?: boolean = false;

  constructor(
    private settings: AppSettings,
    private messageService: MessageService
  ) {}

  private bankLinkerRunning = false;
  private isBankLinked = false;

  linkBank(): void {
    const that = this;

    const linkTokenUrl = this.settings.banking.url + "/api/plaid/link-token-3";
    const createItemnUrl = this.settings.banking.url + "/api/plaid/items-3";

    if (this.bankLinkerRunning) {
      console.log("bank linker already running");
      return;
    } else if (this.isBankLinked) {
      console.log("already linked bank account");
      return;
    } else {
      console.log("starting bank linker...");
      this.bankLinkerRunning = true;
    }

    const redirectUri = window.location.href.split(/[?#]/)[0];
    const createLinkTokenRequest3 = {
      applicationUuid: this.applicationUuid,
      redirectUri: redirectUri,
    };

    fetch(linkTokenUrl, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(createLinkTokenRequest3),
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return Promise.resolve(response);
        } else {
          console.log(response);
          if (response.status === 405) {
            that.isBankLinked = true;
            throw new Error("bank is already linked");
          } else throw new Error(response.statusText);
        }
      })
      .then((response) => response.text())
      .then((token) => {
        return new Promise(function (resolve: any, reject) {
          //https://plaid.com/docs/link/web/
          const handler = window.Plaid.create({
            token: token,
            receivedRedirectUri: null,

            //The onSuccess callback is called when a user successfully links an Item.
            onSuccess: function (public_token, metadata) {
              resolve({
                public_token: public_token,
                metadata: metadata,
              });
            },

            //The onExit callback is called when a user exits Link without successfully linking an Item,
            //or when an error occurs during Link initialization.
            onExit: function (error, _metadata) {
              handler.exit();
              reject({
                action:"canceled-by-user",
                message: "user exits Link without successfully linking an Item or error occured. " + error
              });
            },

            //The onEvent callback is called at certain points in the Link flow.
            onEvent: function (eventName, _metadata) {
              console.log(eventName);
            },
          });

          handler.open();
        });
      })
      .then((response: any) => {
        const createItemRequest3 = {
          applicationUuid: that.applicationUuid,
          publicToken: response.public_token,
          institutionId: response.metadata.institution.institution_id,
          institutionName: response.metadata.institution.name,
          accounts: response.metadata.accounts,
        };

        return new Promise(function (resolve: any, reject) {
          fetch(createItemnUrl, {
            method: "POST",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(createItemRequest3),
          }).then((response) => {
            if (response.status >= 200 && response.status < 300) {
              resolve(response);
            } else {
              reject(response.statusText);
            }
          });
        });
      })
      .then((response: Response) => response.text())
      .then((_itemId) => {
        that.bankLinkerRunning = false;
        that.isBankLinked = true;
      })
      .catch((error) => {
        if (error?.action === "canceled-by-user") {
          console.error(error.message);
        } else {
          this.messageService.errorDefault(error);
        }
        that.bankLinkerRunning = false;
      });
  }
}
