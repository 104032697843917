import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BaseCalculatorComponent } from "../../calculator/base/base-calculator.component";
import { ActivatedRoute } from "@angular/router";
import { AppBarTitleService } from "common";
import { DomSanitizer } from "@angular/platform-browser";
import { CalculatorService } from "../../calculator/calculator.service";
import { AppSettings } from "../../../app.settings";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import { ApplicationService } from "../application.service";
import { ChartConfiguration } from "chart.js";
import { getBarChartData, getBarChartOptions } from "./term-loan-bar-chart-data";
import { take } from "rxjs/operators";

@Component({
  selector: "ifa-term-loan-content",
  templateUrl: "./term-loan-content.component.html",
  styleUrls: ["./term-loan-content.component.scss"],
})
export class TermLoanContentComponent
  extends BaseCalculatorComponent
  implements OnChanges
{
  @Input() customerView: boolean;

  private _chartData: ChartData = null;

  private _barChartData: ChartConfiguration<"bar">["data"] = null;
  private _barChartOptions: ChartConfiguration<"bar">["options"] = null;

  private parentPath: string;

  constructor(
    route: ActivatedRoute,
    sanitizer: DomSanitizer,
    appBarTitleService: AppBarTitleService,
    calculatorService: CalculatorService,
    applicationService: ApplicationService,
    settings: AppSettings,
    percentPipe: PercentPipe,
    currencyPipe: CurrencyPipe
  ) {
    super(
      route,
      sanitizer,
      appBarTitleService,
      calculatorService,
      applicationService,
      settings,
      percentPipe,
      currencyPipe
    );

    route.parent.url.pipe(take(1)).subscribe(url => {
      this.parentPath = url[0].path;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._barChartData = null;
    this._barChartOptions = null;

    super.ngOnChanges(changes);
  }

  get factorRate(): number {
    return this.offerCalculator.rate / 100 + 1;
  }

  get barChartData(): any {
    if (!this._barChartData) {
      this._barChartData = this.prepareBarChartData();
    }

    return this._barChartData;
  }

  get barChartOptions(): any {
    if (!this._barChartOptions) {
      this._barChartOptions = this.prepareBarChartOptions();
    }

    return this._barChartOptions;
  }

  showDisclosures(): boolean {
    window.open(`/${this.parentPath}/disclosures?uuid=${this.offerCalculator.application?.uuid}&offerId=${this.offerCalculator.applicationOffer.id}`, "_blank");
    return false;
  }

  private get chartData(): ChartData {
    this._chartData = {
      labels: [],
      interest: [],
      principal: [],
      total: [],
    };

    const payments = this._calculatorService.getTermLoanPayments(this.offer);

    for (let idx = 0; idx < payments.length; idx++) {
      this._chartData.labels.push(payments[idx].label);
      this._chartData.interest.push(payments[idx]?.interestBalance);
      this._chartData.principal.push(payments[idx]?.principalBalance);
      this._chartData.total.push(
        payments[idx]?.interestBalance + payments[idx]?.principalBalance
      );
    }

    return this._chartData;
  }

  private prepareBarChartData(): ChartConfiguration<"bar">["data"] {
    return getBarChartData(this.chartData.total, this.chartData.labels);
  }

  private prepareBarChartOptions(): ChartConfiguration<"bar">["options"] {
    return getBarChartOptions;
  }
}

class ChartData {
  labels: string[];
  interest: number[];
  principal: number[];
  total: number[];
}
