<ifa-share-offer class="table-wrapper"
                 *ngIf="!customerView"
                 [offer]="offer"></ifa-share-offer>

<div class="term-card card-header-green"
     fxLayout="column"
     ngClass.lt-md="term-card-sm"
     ngClass.lt-sm="term-card-xs">
  <div class="term-card-header"
       fxLayout="row"
       fxLayoutAlign="start center"
       fxLayoutGap="16px">
    <div class="avatar mat-green-secondary">TL</div>
    <div class="mat-headline">Term Loan{{offerCalculator.express ? ' *' : ''}}</div>
  </div>
  <div fxLayout="column"
       fxLayoutGap="16px"
       fxLayout.lt-md="column"
       fxLayoutAlign="center center">
    <ifc-bar-chart [data]="barChartData"
                        [options]="barChartOptions">
    </ifc-bar-chart>
    <span class="sub-title">Outstanding balance over time</span>
  </div>
  <div class="express-footer"
       *ngIf="offerCalculator.express">
    * Express Checkout is not subject to additional credit underwriting.
  </div>
</div>

<mat-card class="sample-details"
          ngClass.lt-md="sample-details-sm"
          ngClass.lt-sm="sample-details-xs">
  <mat-card-content fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxLayout.lt-md="column">
    <section fxFlex="48%">
      <span class="mat-title">Details</span>
      <mat-list>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Loan amount</span>
          <span class="item-content">{{offerCalculator.amount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Term</span>
          <span class="item-content">{{offerCalculator.term}} months</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Total payback</span>
          <span class="item-content">{{offerCalculator.totalPayback | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Fee ({{offerCalculator.drawFeeFormatted}})</span>
          <span class="item-content">{{offerCalculator.drawFee | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.originationFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Origination fee</span>
          <span class="item-content">{{offerCalculator.originationFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee</span>
          <span class="item-content">{{offerCalculator.maintenanceFeeAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="offerCalculator.maintenanceFeeAmount">
          <span class="item-title secondary-text"
                mat-line>Maintenance fee frequency</span>
          <span class="item-content">{{offerCalculator.maintenanceFeeFrequencyName}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Disbursed amount</span>
          <span class="item-content">{{offerCalculator.disbursedAmount | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>Factor rate</span>
          <span class="item-content">{{factorRate | number:'1.4-4'}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span class="item-title secondary-text"
                mat-line>{{offerCalculator.paymentFrequencyName}} payment</span>
          <span class="item-content">{{offerCalculator.termPayment | currency:'USD':'symbol':'1.2-2'}}</span>
          <mat-divider *ngIf="!customerView && !isRenewal"></mat-divider>
        </mat-list-item>
        <mat-list-item *ngIf="!customerView && !isRenewal">
          <span class="item-title secondary-text"
                mat-line>Commissions ({{offer.maxUpsell / 100 | percent:'1.2-2'}})</span>
          <span class="item-content">{{offerCalculator.commision | currency:'USD':'symbol':'1.2-2'}}</span>
        </mat-list-item>
      </mat-list>
    </section>
    <section fxFlex="48%"
             class="features">
      <span class="mat-title">Other features</span>
      <div class="content"
           fxLayout="column">
        <p>
          <span class="title">Disbursed amount:</span> The disbursed amount that we will transfer to your designated
          bank account will be
          reduced by any amount owed to Idea Financial from a prior loan or used to pay off an amount owed to a third
          party.
        </p>
        <p>
          <span class="title">Payoff discount:</span> Unpaid finance charges will be reduced by 50% should you pay off this
          loan in full before
          your
          last payment is due. The remaining unpaid finance charges are due upon full pay off.
        </p>
        <p>
          <span class="title">Renewals:</span> After you have paid your loan by 50% you may be eligible for renewal
          subject to a credit
          review.
        </p>
        <p *ngIf="offerCalculator.hasDisclosures">
          <a (click)="showDisclosures()" class="disclosure-link">Click here to read disclosures required by law.</a>
        </p>
      </div>
    </section>
  </mat-card-content>
</mat-card>

<ifa-calculator-closing-requirements [checkoutRequirements]="checkoutRequirements">
</ifa-calculator-closing-requirements>

<ifa-link-your-bank [applicationUuid]="uuid" [bankLinkStatus]="application?.bankLinkStatus" ></ifa-link-your-bank>

<ifa-calculator-payment-schedule [offer]="offer"
                                 [sampleDraw]="offerCalculator.sampleDraw"></ifa-calculator-payment-schedule>
