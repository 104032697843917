import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CalculatorHeadingComponent } from './calculator-heading.component';

@NgModule({
    declarations: [
        CalculatorHeadingComponent
    ],
    imports: [
        MatIconModule,
        CommonModule,
        FlexLayoutModule 
    ],
    exports: [
        CalculatorHeadingComponent
    ]
})
export class CalculatorHeadingModule { }