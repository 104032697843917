import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../lead.service';

@Component({
  selector: 'ifa-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
  providers: [LeadService]
})
export class LeadSubmittedComponent implements OnInit, AfterViewInit {

  fullName: string;
  text: string;

  constructor(private route: ActivatedRoute, private router: Router, private leadService: LeadService) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (JSON.parse(params['includeAttachments']))
          this.text = 'Thank you for your application! We will send you an email shortly to connect your bank so we can get you the best offers FAST.';
        else
          this.text = 'Thanks for your application! It looks like we are still missing your bank statements. Feel free to send them over to <a href=\"mailto:applications@ideafinancial.com\">applications@ideafinancial.com</a>. We will also be sending you an email shortly to connect your bank in order to get you the best offers FAST.';
      });

    const lead = this.leadService.getLeadFromSession();
    if (!lead)
      this.router.navigate(['']);
    else if (lead.business.owners.length)
      this.fullName = `${lead.business.owners[0].firstName} ${lead.business.owners[0].lastName}`;
  }

  ngAfterViewInit(): void {
    localStorage.removeItem('lead');
  }

}
