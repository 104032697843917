import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { BankLinkingRoutingModule } from "./bank-linking-routing.module";
import { LinkYourBankPageComponent } from "./link-your-bank-page/link-your-bank-page.component";
import { LinkYourBankModule } from "../shared/link-your-bank/link-your-bank.module";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LinkYourBankModule,
    BankLinkingRoutingModule,
  ],
  declarations: [LinkYourBankPageComponent],
})
export class BankLinkingModule {}
