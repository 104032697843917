import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationOffer, Payment, ProductCode } from 'common';
import { CalculatorService } from '../../calculator/calculator.service';

@Component({
  selector: 'ifa-payment-schedule-table',
  templateUrl: './payment-schedule-table.component.html',
  styleUrls: ['./payment-schedule-table.component.scss']
})
export class PaymentScheduleTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() offerDetail: ApplicationOffer;
  @Input() sampleDraw: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource = new MatTableDataSource<Payment>();
  displayedColumns: string[] = [
    'date',
    'amount',
    'principalAmount',
    'interestAmount',
    'balance',
    'totalInterestCost',
    'capitalRepayment',
    'totalPayback'
  ];

  constructor(private calculatorService: CalculatorService) { }

  ngOnInit(): void {
    this.calculatePayments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculatePayments();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  calculatePayments() {
    var payments: Payment[];
    if (this.offerDetail.productCode == ProductCode.Term)
      payments = this.calculatorService.getTermLoanPayments(this.offerDetail);
    else if (this.offerDetail.productCode == ProductCode.LOC || this.offerDetail.productCode == ProductCode.Law)
      payments = this.calculatorService.getLineOfCreditLoanPayments(this.offerDetail, this.sampleDraw);

    this.dataSource = new MatTableDataSource(payments);

    if (this.paginator) {
      this.paginator.firstPage();
      this.paginator.pageSize = 10;
      this.dataSource.paginator = this.paginator;
    }
  }
}