<form [formGroup]="form">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
      <mat-label>Street address</mat-label>
      <input #inlineInput matInput formControlName="street" type="text" autocomplete="address-line1">
    </mat-form-field>
    
    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
      <mat-label>Unit/Suite/Apt #</mat-label>
      <input matInput formControlName="unitNumber" type="text" autocomplete="address-line2">
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
      <mat-label>City</mat-label>
      <input #cityInput matInput formControlName="city" type="text" autocomplete="address-level2">
    </mat-form-field>
    
    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-half-md">
      <mat-label>State</mat-label>
      <mat-select matInput type="text" formControlName="state" autocomplete="address-level1">
        <mat-option *ngFor="let state of states" [value]="state.value">{{state.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-half-md">
      <mat-label>Zip code</mat-label>
      <input matInput formControlName="zipCode" type="text" autocomplete="postal-code">
    </mat-form-field>

    
  </div>

</form>
