import { LocOfferCalculator } from "./loc-offer-calculator";

export class LawOfferCalculator extends LocOfferCalculator {
    protected getAnnualRate(): number {
        return this.rate / 100;
    }

    protected getMonthlyRate(): number {
        return this.annualRate / 12;
    }
}