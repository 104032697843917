import { Component } from '@angular/core';
import * as _ from 'lodash';
import { BaseLocCalculatorComponent } from '../base/base-loc-calculator.component';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, ProductCode } from 'common';
import { DomSanitizer } from '@angular/platform-browser';
import { CalculatorService } from '../calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationService } from '../../shared/application.service';

@Component({
    selector: 'ifa-loc-calculator',
    templateUrl: './loc-calculator.component.html',
    styleUrls: ['./../base/base-calculator.component.scss', './loc-calculator.component.scss']
})
export class LocCalculatorComponent extends BaseLocCalculatorComponent {

    constructor(
        route: ActivatedRoute,
        sanitizer: DomSanitizer,
        appBarTitleService: AppBarTitleService,
        calculatorService: CalculatorService,
        applicationService: ApplicationService,
        settings: AppSettings,
        percentPipe: PercentPipe,
        currencyPipe: CurrencyPipe
      ) {
          
        super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
    }

    ngAfterContentInit(): void {
        this._appBarTitleService.title = 'See all products';
        this._calculatorService.bundleChanged.subscribe(bundle => this.offer = _.first(bundle?.offers, { productCode: ProductCode.LOC }));
    }


}