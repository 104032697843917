<link [href]="brandingCss" rel="stylesheet">

<div class="ifa-calc-wrapper" fxLayout="column">

  <ifa-product-navigator *ngIf="navigator?.bestLocOfferBundle || navigator?.bestLawOfferBundle" [offerNavigator]="navigator" [customerView]="customerView"></ifa-product-navigator>
  
  <ifa-repayment-navigator *ngIf="navigator?.termOfferBundles && navigator?.termOfferBundles.length > 1" [offerBundles]="navigator?.termOfferBundles" 
    [currentOfferBundle]="navigator?.currentOfferBundle" [customerView]="customerView"></ifa-repayment-navigator>

  <h4 class="layout padding-left padding-right">
    <ifa-calculator-heading [businessName]="bundle?.businessName"></ifa-calculator-heading>
  </h4>

  <ifa-term-loan-content [offer]="offer" [customerView]="customerView"></ifa-term-loan-content>

</div>
