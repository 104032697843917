import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TermLoanContentComponent } from './term-loan-content.component';
import { NgChartsModule } from 'ng2-charts';
import { PaymentScheduleTableModule } from '../payment-schedule-table/payment-schedule-table.module';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ShareOfferModule } from '../share-offer/share-offer.module';
import { CalculatorClosingRequirementsModule } from '../calculator-closing-requirements/calculator-closing-requirements.module';
import { CalculatorPaymentScheduleModule } from '../calculator-payment-schedule/calculator-payment-schedule.module';
import { LinkYourBankModule } from '../link-your-bank/link-your-bank.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgChartsModule,
    PaymentScheduleTableModule,
    ShareOfferModule,
    CalculatorClosingRequirementsModule,
    CalculatorPaymentScheduleModule,
    LinkYourBankModule
  ],
  declarations: [
    TermLoanContentComponent
  ],
  exports: [
    TermLoanContentComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class TermLoanContentModule { }
