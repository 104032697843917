import { NgModule } from "@angular/core";
import { CommonModule } from "common";
import { LinkYourBankComponent } from "./link-your-bank.component";

@NgModule({
  imports: [CommonModule],
  declarations: [LinkYourBankComponent],
  exports: [LinkYourBankComponent],
})
export class LinkYourBankModule {}
