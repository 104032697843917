import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppBarComponent, AppBarContactComponent, AppFooterComponent, AppPageComponent } from 'common';
import { LeadFlowStepperComponent } from './lead-flow/lead-flow-stepper/lead-flow-stepper.component';
import { LeadSubmittedComponent } from './lead-flow/submitted/submitted.component';
import { LeadResolver } from './lead.resolver';

const routes: Routes = [
  {
    path: '',
    data: {
      appBar: { stickyDisabled: true },
      appPage: { contentClass: 'simple' }
    },
    component: AppPageComponent,
    resolve: {
      lead: LeadResolver
    },
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      },
      {
        path: '',
        component: LeadFlowStepperComponent,
      }
    ]
  },
  {
    path: 'thank-you',
    data: {
      appBar: { stickyDisabled: true },
      appPage: { contentClass: 'simple' }
    },
    component: AppPageComponent,
    children: [
      {
        path: '',
        outlet: 'app-bar',
        component: AppBarComponent,
        children: [
          {
            path: '',
            outlet: 'right',
            component: AppBarContactComponent
          }
        ]
      },
      {
        path: '',
        outlet: 'app-footer',
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true
          }
        }
      },
      {
        path: '',
        component: LeadSubmittedComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadRoutingModule { }
