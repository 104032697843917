import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { LineOfCreditContentComponent } from './line-of-credit-content.component';
import { MatSliderModule } from '@angular/material/slider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LineOfCreditExternalCalculatorComponent } from './external-calculator/line-of-credit-external-calculator.component';
import { PaymentScheduleTableModule } from '../payment-schedule-table/payment-schedule-table.module';
import { CarouselSliderModule } from '../carousel-slider/carousel-slider.module';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ShareOfferModule } from '../share-offer/share-offer.module';
import { CalculatorClosingRequirementsModule } from '../calculator-closing-requirements/calculator-closing-requirements.module';
import { CalculatorPaymentScheduleModule } from '../calculator-payment-schedule/calculator-payment-schedule.module';
import { CalculatorDetailsTableModule } from '../calculator-details-table/calculator-details-table.module';
import { CalculatorDrawSliderModule } from '../calculator-draw-slider/calculator-draw-slider.module';
import { LinkYourBankModule } from '../link-your-bank/link-your-bank.module';

@NgModule({
  imports: [
    CommonModule,
    MatSliderModule,
    CarouselSliderModule,
    FlexLayoutModule,
    PaymentScheduleTableModule,
    ShareOfferModule,
    CalculatorClosingRequirementsModule,
    CalculatorPaymentScheduleModule,
    CalculatorDetailsTableModule,
    CalculatorDrawSliderModule,
    LinkYourBankModule
  ],
  declarations: [
    LineOfCreditContentComponent,
    LineOfCreditExternalCalculatorComponent
  ],
  exports: [
    LineOfCreditContentComponent,
    LineOfCreditExternalCalculatorComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class LineOfCreditContentModule { }
