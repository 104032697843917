import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AppBarComponent,
  AppBarContactComponent,
  AppFooterComponent,
  AppPageComponent,
} from "common";
import { LinkYourBankPageComponent } from "./link-your-bank-page/link-your-bank-page.component";

const routes: Routes = [
  {
    path: "link-your-bank",
    data: {
      appBar: { stickyDisabled: true },
      appPage: { contentClass: "simple" },
    },
    component: AppPageComponent,
    children: [
      {
        path: "",
        outlet: "app-bar",
        component: AppBarComponent,
        children: [
          {
            path: "",
            outlet: "right",
            component: AppBarContactComponent,
          },
        ],
      },
      {
        path: "",
        outlet: "app-footer",
        component: AppFooterComponent,
        data: {
          appFooter: {
            full: true,
          },
        },
      },
      {
        path: "",
        component: LinkYourBankPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BankLinkingRoutingModule {}
