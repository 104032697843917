<link [href]="brandingCss" rel="stylesheet">

<div class="ifa-calc-wrapper" fxLayout="column">

  <ifa-product-navigator *ngIf="navigator?.bestTermOfferBundle || navigator?.bestLawOfferBundle" [offerNavigator]="navigator" [customerView]="customerView"></ifa-product-navigator>

  <ifa-repayment-navigator *ngIf="navigator?.locOfferBundles && navigator?.locOfferBundles.length > 1" [offerBundles]="navigator?.locOfferBundles" 
    [currentOfferBundle]="navigator?.currentOfferBundle" [customerView]="customerView"></ifa-repayment-navigator>

  <h4 class="layout padding-left padding-right">
    <ifa-calculator-heading [businessName]="bundle?.businessName"></ifa-calculator-heading>
  </h4>  

  <ifa-line-of-credit-content [offer]="offer" [customerView]="customerView"></ifa-line-of-credit-content>

</div>
