<nav ngClass.lt-sm="hidden">
  <button *ngFor="let offerBundle of offerBundles" (click)="click(offerBundle)" [class.active]="isCurrentBundle(offerBundle)">
    {{ (offerBundle.express ? 'express ' : '') + offerBundle.repayment + (!offerBundle.express ? ' months' : '') }}
  </button>
</nav>
<mat-form-field appearance="outline" ngClass.gt-xs="hidden">
  <mat-label>Repayment term</mat-label>
  <mat-select matInput formControlName="timeInBusiness" autocomplete="off" [(value)]="selectedBundle">
    <mat-option [value]="offerBundle.bundleId" *ngFor="let offerBundle of offerBundles" (click)="click(offerBundle)">
      {{ (offerBundle.express ? 'express ' : '') + offerBundle.repayment + (!offerBundle.express ? ' months' : '') }}
    </mat-option>
  </mat-select>
</mat-form-field>