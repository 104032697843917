<div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="24px" fxLayout.lt-md="column" class="layout padding-left padding-right">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start center"  >
      <span class="primary-text">Share offer:</span>
      <button mat-icon-button type="button" (click)="sendEmailOffer()" class="mat-green-bg small-icon-button"  >
        <mat-icon class="mat-white">send</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="copy()" class="mat-green-bg  small-icon-button">
        <mat-icon class="mat-white " svgIcon="">content_copy</mat-icon>
      </button>
      <span>Customer view:</span>
      <button mat-icon-button type="button" class="mat-green-bg small-icon-button" (click)="customerView()">
        <mat-icon class="mat-white">visibility</mat-icon>
      </button>
    </div>
</div>
