import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LocalSettings  { 
    constructor() { }

    get applicationFormRedirection() {
        return LocalSettings.redirectionInformation;
    }

    public static redirectionInformation: {
        allowed: boolean;
        url: string;
    }
}