<div class="main" ngClass.lt-sm="layout padding-md">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <div class="card-aside">
      <span class="mat-display-1 break-word">Thank you {{fullName}}!</span>
    </div>
    <div class="text secondary-text layout padding-horizontal">
      <span [innerHTML]="text"></span>
    </div>
  </div>
</div>
