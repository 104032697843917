import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { OfferCalculator } from '../../calculator/offer-calculator';

@Component({
  selector: 'ifa-calculator-details-table',
  templateUrl: './calculator-details-table.component.html',
  styleUrls: ['./calculator-details-table.component.scss']
})
export class CalculatorDetailsTableComponent {

  @Input() offerCalculator: OfferCalculator
  @Input() showCommision: boolean
  @Input() showTerm: boolean

  private parentPath: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.parent.url.pipe(take(1)).subscribe(url => {
      this.parentPath = url[0].path;
    });
  }

  showDisclosures(): boolean {
    window.open(`/${this.parentPath}/disclosures?uuid=${this.offerCalculator.application?.uuid}&offerId=${this.offerCalculator.applicationOffer.id}`, "_blank");
    return false;
  }
}
