import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShareOfferComponent } from './share-offer.component';
import { NgChartsModule } from 'ng2-charts';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { SendOfferEmailComponent } from './send-offer-email/send-offer-email.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    NgChartsModule
  ],
  declarations: [
    ShareOfferComponent,
    SendOfferEmailComponent
  ],
  exports: [
    ShareOfferComponent,
    SendOfferEmailComponent
  ],
  providers: [
    PercentPipe,
    CurrencyPipe
  ]
})
export class ShareOfferModule { }
