<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">

  <div class="layout padding-top-sm">
    <span class="mat-headline">Tell us about yourself</span>
  </div>

  <form [formGroup]="form">

    <div formArrayName="owners" *ngFor="let item of form.get('owners')['controls']; let ownerIndex = index;">

      <div [formGroupName]="ownerIndex">

        <div fxLayout="row" fxLayoutAlign="space-between start">
          <span class="mat-body-1 secondary-text">Owner {{ownerIndex + 1}}</span>
          <button *ngIf="ownerIndex > 0" mat-icon-button type="button" (click)="removeOwner(ownerIndex)">
            <mat-icon class="secondary-text">close</mat-icon>
          </button>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>First name</mat-label>
            <input matInput formControlName="firstName" type="text" focus="true" autocomplete="given-name">
          </mat-form-field>

          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>Last name</mat-label>
            <input matInput formControlName="lastName" type="text" autocomplete="family-name">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email" (blur)="emailChanged($event.target.value, ownerIndex)" autocomplete="email">
          </mat-form-field>

          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>Cell phone number</mat-label>
            <input matInput formControlName="phoneNumber" type="text" customMask="(000) 000-0000" inputmode="numeric" autocomplete="tel-national">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start">
          <span class="mat-body-1 secondary-text">Home address</span>
        </div>

        <ifa-address [form]="item['controls'].address"></ifa-address>

        <div fxLayout="row" fxLayoutAlign="start start">
          <span class="mat-body-1 secondary-text">Birthdate</span>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
          <div formGroupName="birthDate" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
            fxLayoutGap="12px">
            <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-third-md">
              <mat-label>Month</mat-label>
              <mat-select formControlName="month" autocomplete="bday-month">
                <mat-option *ngFor="let month of months" [value]="month.value">{{month.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-third-md">
              <mat-label>Day</mat-label>
              <mat-select formControlName="day" autocomplete="bday-day">
                <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-third-md">
              <mat-label>Year</mat-label>
              <mat-select formControlName="year" autocomplete="bday-year">
                <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutAlign="start start" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
              <mat-label>Social security number</mat-label>
              <input matInput formControlName="ssn" type="text" customMask="000-00-0000" inputmode="numeric" autocomplete="off">
            </mat-form-field>
            <div class="help-text">Applying doesn't affect your credit score. We use advance encryption to keep your
              information safe. Learn more <a class="pointer" (click)="openSSLDialog()">here</a></div>
          </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start" fxLayoutGap="12px">
          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>Estimated personal credit score</mat-label>
            <mat-select matInput formControlName="ficoScore" autocomplete="off">
              <mat-option value="549"> Less than 550 </mat-option>
              <mat-option value="550"> 550 - 599 </mat-option>
              <mat-option value="600"> 600 - 649 </mat-option>
              <mat-option value="650"> 650 - 699 </mat-option>
              <mat-option value="700"> 700 - 749 </mat-option>
              <mat-option value="750"> 750 or more </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" ngClass.xs="form-field-xs" ngClass.gt-xs="form-field-md">
            <mat-label>Ownership percentage</mat-label>
            <input matInput formControlName="ownership" type="text" currencyMask [options]="{precision: 0, suffix: '%', prefix: ''}" inputmode="numeric">
          </mat-form-field>
        </div>

      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start start" *ngIf="!(form.get('owners')['controls'].length > 3)">
      <button mat-button type="button" (click)="addOwner()">
        <mat-icon class="mat-gray">add_circle</mat-icon>
        <span> ADD ANOTHER OWNER</span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" class="layout padding-md"
      [ngClass]="{'required': form.get('agreedToTerms').dirty && form.get('agreedToTerms').invalid}">
      <mat-checkbox color="primary" formControlName="agreedToTerms"></mat-checkbox>
      <span class="mat-caption">I agree to Idea Financial's <a class="pointer" (click)="openTermsDialog()"> Credit
          Application terms</a></span>
    </div>

    <div fxLayout="row" fxFlexAlign="space-between center" fxFlexFill class="layout padding-top">
      <div fxLayoutAlign="end" fxLayoutGap="8px" fxFlex="100">
        <button mat-button type="button" matStepperPrevious>
          Back
        </button>
        <button mat-raised-button type="submit" color="primary" (click)="continue()" matStepperNext>
          Continue
        </button>
      </div>
    </div>

  </form>

  <ifc-ssl-info fxLayout="row" fxLayoutAlign="center strech"></ifc-ssl-info>

</div>
