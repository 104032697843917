import { Component, Input, OnInit } from '@angular/core';
import { BrandingService } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifa-calculator-heading',
  templateUrl: './calculator-heading.component.html',
  styleUrls: ['./calculator-heading.component.scss']
})
export class CalculatorHeadingComponent implements OnInit {
  @Input() businessName: string
  companyName: string
  companyCssClass: string

  constructor(private brandingService: BrandingService) { }

  ngOnInit(): void {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe(companyData => {
        this.companyName = companyData.dba;
        this.companyCssClass = `companyName_${companyData.colorSchema}`
      });
  }
}
