import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService} from 'common';
import { DomSanitizer } from '@angular/platform-browser';
import { CalculatorService } from '../../calculator/calculator.service';
import { AppSettings } from '../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationService } from '../application.service';
import { BaseCalculatorComponent } from '../../calculator/base/base-calculator.component';

@Component({
  selector: 'ifa-law-case-financing-content',
  templateUrl: './law-case-financing-content.component.html',
  styleUrls: ['./law-case-financing-content.component.scss']
})
export class LawCaseFinancingContentComponent extends BaseCalculatorComponent {

  //flag to display component in customer or partner view
  @Input() customerView: boolean

  constructor(
    route: ActivatedRoute,
    sanitizer: DomSanitizer,
    appBarTitleService: AppBarTitleService,
    calculatorService: CalculatorService,
    applicationService: ApplicationService,
    settings: AppSettings,
    percentPipe: PercentPipe,
    currencyPipe: CurrencyPipe
  ) {
    super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
  }

}