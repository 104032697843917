<mat-dialog-content class="mat-typography">
  <h2>Email offer</h2>
  <h4 class="secondary-text">{{data.application?.businessName}} has been approved for funding from {{companyName}}. Send
    offer details to the email below</h4>
  <form [formGroup]="form">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="24px"
      fxLayoutAlign.xs="start center" fxLayoutGap.xs="0px">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input #inlineInput matInput formControlName="email" focus="true" width="100%" type="text">
      </mat-form-field>
    </div>

  </form>
</mat-dialog-content>
<div mat-dialog-actions class="flex row end-center">
  <button mat-button [mat-dialog-close]="false">CANCEL</button>
  <a href="mailto:{{form.get('email').value}}?Subject=Your {{companyName}} {{productCodeLabel(data?.offer?.productCode)}} Offer&body={{emailBody}}" mat-button
    [mat-dialog-close]="true" color="primary">SEND</a>
</div>