import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[focus]' })
export class FocusDirective implements OnInit {

  @Input() focus: boolean;

  constructor(private hostElement: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.focus) {
      this.hostElement.nativeElement.focus();
    }
  }
}
