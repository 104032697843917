import { Component, AfterContentInit } from '@angular/core';
import { CalculatorService } from '../../../calculator/calculator.service';
import { ActivatedRoute } from '@angular/router';
import { AppBarTitleService, FeeType, PaymentFrequency } from 'common';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSettings } from '../../../../app.settings';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { ApplicationService } from '../../application.service';
import { BaseCalculatorComponent } from '../../../calculator/base/base-calculator.component';

@Component({
  selector: 'ifa-line-of-credit-external-calculator',
  templateUrl: './line-of-credit-external-calculator.component.html',
  styleUrls: ['./line-of-credit-external-calculator.component.scss']
})
export class LineOfCreditExternalCalculatorComponent extends BaseCalculatorComponent implements AfterContentInit {

  amount: number;
  totalPayback: number;
  rate: number;
  term: number;
  fee: number;
  feeType: FeeType;
  paymentFrequency: PaymentFrequency;

  get PaymentFrequency() { return PaymentFrequency; }

  constructor(
    route: ActivatedRoute,
    sanitizer: DomSanitizer,
    appBarTitleService: AppBarTitleService,
    calculatorService: CalculatorService,
    applicationService: ApplicationService,
    settings: AppSettings,
    percentPipe: PercentPipe,
    currencyPipe: CurrencyPipe
  ) {
    super(route, sanitizer, appBarTitleService, calculatorService, applicationService, settings, percentPipe, currencyPipe);
  }

  ngAfterContentInit(): void {
    this.amount = 250000;
    this.rate = 0.24;
    this.term = 18;
    this.fee = 2;
    this.feeType = FeeType.Percentage;
    this.paymentFrequency = PaymentFrequency.Weekly;
  }

  onSampleAmountDisplay(value: number): string {
    return Math.round(value / 1000).toString() + 'K';
  }

  onSampleAmountChanged(event: any): void {
    this.offerCalculator.sampleDraw = event.value;
  }

  setTerm(term: number) {
    this.term = term;
  }

  setPaymentFrequency(paymentFrequency: PaymentFrequency) {
    this.paymentFrequency = paymentFrequency;
  }

}
