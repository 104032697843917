export abstract class BaseDisclosureInfo {
    creditLimit: number;
    creditLimitTooltip: string;

    interestRate: number;
    interestRateTooltip: string;

    paymentFrequency: string;
    paymentFrequencyTooltip = 'Indicates how often we will debit customer account.';

    financedAmount: number;
    financedAmountTooltip: string;

    paybackAmount: number;
    paybackAmountTooltip = 'The total payments customer will make during the term of the loan if customer makes minimum required payments.';

    totalFinanceChargeAmount: number;
    totalFinanceChargeAmountTooltip: string;

    averageMonthlyPaymentAmount: number;
    averageMonthlyPaymentAmountTooltip = 'The average monthly cost is the average total amount paid by the customer over the term of the loan, divided by the number of months in the term of the loan. This line only appears if loan payment frequency is not monthly.';

    paymentAmount: number;
    get paymentAmountTooltip(): string {
        return `Principal and interest payment amount we will debit customer's bank account each ${this.termName}.`;
    }

    repaymentTermYearsMonths: string;
    repaymentTermYearsMonthsTooltip = 'Repayment period of the loan. This shows the time it will take to repay the loan after last draw, if making only required minimum payments.';

    prepaidFinanceChargesAmount: number;
    prepaidFinanceChargesAmountTooltip = 'Total amount of all deferred fees (draw fee, origination fee, etc.) plus renewal fee.';

    apr: number;
    aprTooltip: string;

    get disbursedAmount(): number {
        return this.netAmount - this.otherDeferredFeeAmount;
    }
    disbursedAmountTooltip = 'This is the amount customer will have credited to their bank account. This amount is entire credit limit minus all deferred fees (such as draw fee, etc.) and minus total payoff amount (balance transfer in case of renewal, and third party payoff).';

    get air(): number {
        return this.interestRate / 100;
    }
    airTooltip: string;

    dbaOrName: string;
    repaymentTerm: number;
    renewalFeeAmount: number;
    otherDeferredFeeAmount: number;
    otherCashFeeAmount: number;
    balanceTransferAmount: number;
    payoffAmount: number;

    termName: string;
    paymentsPerYear: number;
    averageDaysInTerm: number;
    totalPayoffAmount: number;
    netAmount: number;
    drawFeeChargedOnAmount: number;

    constructor(
        dbaOrName?: string,
        creditLimit?: number, interestRate?: number, paymentFrequency?: string,
        repaymentTerm?: number,
        renewalFee?: number, otherDeferredFee?: number,
        otherCashFee?: number, balanceTransfer?: number, payoff?: number) {

        this.dbaOrName = dbaOrName;
        this.creditLimit = creditLimit ? creditLimit : 0;
        this.interestRate = interestRate ? interestRate : 0;
        this.paymentFrequency = paymentFrequency ? paymentFrequency : 'weekly';
        this.repaymentTerm = repaymentTerm ? repaymentTerm : 0;
        this.renewalFeeAmount = renewalFee ? renewalFee : 0;
        this.otherDeferredFeeAmount = otherDeferredFee ? otherDeferredFee : 0;
        this.otherCashFeeAmount = otherCashFee ? otherCashFee : 0;
        this.balanceTransferAmount = balanceTransfer ? balanceTransfer : 0;
        this.payoffAmount = payoff ? payoff : 0;

        switch(this.paymentFrequency) {
            case 'weekly':
                this.termName = 'week';
                this.paymentsPerYear = 52;
                this.averageDaysInTerm = 7;
                break;

            case 'bi-weekly':
                this.termName = '2 weeks';
                this.paymentsPerYear = 26;
                this.averageDaysInTerm = 14;
                break;

            default:
                this.termName = 'month';
                this.paymentsPerYear = 12;
                this.averageDaysInTerm = 365 / 12;
                break;
        }

        this.repaymentTermYearsMonths = this.getTermYearsMonths(repaymentTerm);
        this.totalPayoffAmount = this.balanceTransferAmount + this.payoffAmount;
        this.netAmount = this.creditLimit - this.totalPayoffAmount;
        this.drawFeeChargedOnAmount = this.creditLimit - this.balanceTransferAmount - this.otherDeferredFeeAmount;
    }

    protected abstract calculatePayment(numberOfPayments: number, amount: number): number;

    protected getTermYearsMonths(term: number): string {
        const years = Math.floor(term / 12);
        const months = term - years * 12;

        const yearPlural = years != 1 ? "s" : "";
        const monthPlural = months != 1 ? "s" : "";

        if(months > 0)
            return `${years} year${yearPlural}, ${months} month${monthPlural}`;
        else
            return `${years} year${yearPlural}`;
    }

    protected calculateApr(amount: number, feesAmount: number, interestOnlyPayments: number, regularPayments: number): number {
        const t = this.paymentsPerYear;
        const n1 = interestOnlyPayments;
        const p1 = Math.round(this.interestRate / t * amount) / 100;
        const n2 = regularPayments;
        const p2 = this.calculatePayment(n2, amount);
        const precisionValue = Math.pow(0.1, 4);

        // AK: Calculations according to Appending J to Part 1026 of FDIC publication
        let I: number; // AK: Interest rate expressed in base points (e.g. 25% = 25, not 0.25)
        let I1 = this.interestRate; // AK: Same as above (i) but after iteration is performed
        let A = amount - feesAmount; // AK: net amount customer receives (as refinance or disbursement)

        do
        {
            I = I1;
            let i = I / t / 100;
            let A1 = this.calculateAPV(i, n1, p1, n2, p2);

            i += 0.001 / t;
            let A2 = this.calculateAPV(i, n1, p1, n2, p2);

            I1 += 0.1 * ((A - A1) / (A2 - A1));
        }
        while(Math.abs(I - I1) > precisionValue);

        return Math.round(I1 / precisionValue) * precisionValue / 100;
    }

    // AK: Calculates present value of anniuty with periodic rate r, number of payments n, and regular payment amount p
    protected calculatePV(r: number, n: number, p: number): number {
        return p / r * (1 - 1 / Math.pow(1 + r, n));
    }

    protected calculateAPV(r: number, n1: number, p1: number, n2: number, p2: number) : number {
        return this.calculatePV(r, n1, p1) + this.calculatePV(r, n2, p2) / Math.pow(1 + r, n1);
    }
}

export class LocDisclosureInfo extends BaseDisclosureInfo {
    creditLimitTooltip = 'This is the maximum amount of funding we will provide and it is equal to Line of Credit limit. For the purpose of this disclosure, it is assumed that customer will make a full draw.';
    aprTooltip = 'The APR is a measure of the cost of credit, expressed as a yearly rate, that relates the amount and timing of value received by the recipient to the amount and timing of payments made to the provider. APR is calculated according to Appendix J, 12 C.F.R. Part 1026 (1-1-21 Edition, see https://www.consumerfinance.gov/rules-policy/regulations/1026/j) and is dependent on interest rate and total amount of fees.';
    airTooltip = 'Annual interest rate of the loan.';
    totalFinanceChargeAmountTooltip = 'Finance charge amount reflects true cost of credit and consists of all deferred fees, renewal fee, and total amount of interest paid by the customer if loan is repaid organically.';
    financedAmountTooltip = 'This amount equals to credit limit minus prepaid finance charges. Prepaid finance charges consist of draw fee, renewal fee, and any other deferred fees.';

    drawTerm: number;
    drawTermTooltip = 'Number of months for which the customer is allowed to take additional debt from their line of credit.';

    interestOnlyPaymentAmount: number;
    get interestOnlyPaymentAmountTooltip(): string {
        return `Interest only payment amount the customer will pay each ${this.termName}. Note that while paying this amount principal balance will not reduce.`;
    }

    drawFeeAmount: number;
    interestOnlyTerm: number;
    interestOnlyNumberOfPayments: number;
    totalInterest: number;
    regularRepaymentTerm: number;
    regularNumberOfPayments: number;
    numberOfPayments: number;

    constructor(
        dbaOrName?: string,
        creditLimit?: number, interestRate?: number, paymentFrequency?: string,
        drawTerm?: number, repaymentTerm?: number, interestOnlyTerm?: number,
        drawFee?: number, renewalFee?: number, otherDeferredFee?: number,
        otherCashFee?: number, balanceTransfer?: number, payoff?: number) {

        super(
            dbaOrName,
            creditLimit, interestRate, paymentFrequency,
            repaymentTerm,
            renewalFee, otherDeferredFee,
            otherCashFee, balanceTransfer, payoff);

        this.drawFeeAmount = drawFee ? Math.round(this.drawFeeChargedOnAmount * drawFee) / 100 : 0;
        this.interestOnlyTerm = interestOnlyTerm ? interestOnlyTerm : 0;

        this.prepaidFinanceChargesAmount = this.drawFeeAmount + this.renewalFeeAmount + this.otherDeferredFeeAmount;
        this.financedAmount = this.creditLimit - this.prepaidFinanceChargesAmount;
        this.interestOnlyNumberOfPayments = Math.ceil(this.interestOnlyTerm * this.paymentsPerYear / 12);
        this.regularNumberOfPayments = Math.ceil((this.repaymentTerm - this.interestOnlyTerm) * this.paymentsPerYear / 12);
        this.interestOnlyPaymentAmount = Math.round(this.creditLimit * this.interestRate / this.paymentsPerYear) / 100;
        this.paymentAmount = this.calculatePayment(this.regularNumberOfPayments, creditLimit);
        this.totalInterest = this.calculateTotalInterest(creditLimit, this.paymentAmount);
        this.paybackAmount = this.creditLimit + this.totalInterest;
        this.totalFinanceChargeAmount = this.totalInterest + this.prepaidFinanceChargesAmount;
        this.averageMonthlyPaymentAmount = Math.round(this.paybackAmount / this.repaymentTerm * 100) / 100;
        this.apr = this.calculateApr(this.creditLimit, this.prepaidFinanceChargesAmount, this.interestOnlyNumberOfPayments, this.regularNumberOfPayments);
        this.drawTerm = drawTerm ? drawTerm : 0;
    }

    get disbursedAmount(): number {
        return this.netAmount - this.otherDeferredFeeAmount - this.drawFeeAmount;
    }

    protected calculatePayment(numberOfPayments: number, amount: number): number {
        const p = amount;
        const i = this.interestRate / this.paymentsPerYear / 100;
        const n = numberOfPayments;

        const ip1 = 1 + i;
        const ip1mN = Math.pow(ip1, -n);
        const d = i / (1 - ip1mN);

        const payment = p * d;

        return Math.round(payment * 100) / 100;
    }

    private calculateTotalInterest(principalBalance: number, paymentAmount: number): number {
		const termRate = (this.interestRate / 365) * this.averageDaysInTerm;
        const pniStart = this.interestOnlyNumberOfPayments;

        let totalInterest = 0;
		let periodsCounter = 0;

        while(principalBalance > 0)
        {
            const accruedInterest = Math.round(principalBalance * termRate) / 100;
            totalInterest += accruedInterest;

            if(periodsCounter++ >= pniStart)
            {
                const principalPayment = paymentAmount - accruedInterest;
                principalBalance -= principalPayment;
            }
        }

        return totalInterest;
    }
}

export class TermDisclosureInfo extends BaseDisclosureInfo {
    creditLimitTooltip = 'This is the total amount of the term loan.';
    aprTooltip = 'The APR is a measure of the cost of credit, expressed as a yearly rate, that relates the amount and timing of value received by the recipient to the amount and timing of payments made to the provider. APR is calculated according to Appendix J, 12 C.F.R. Part 1026 (1-1-21 Edition, see https://www.consumerfinance.gov/rules-policy/regulations/1026/j) and is dependent on factor rate and total amount of fees.';
    totalFinanceChargeAmountTooltip = 'Finance charge amount reflects true cost of credit and consists of all deferred fees, renewal fee, and factor rate paid by the customer if term loan is repaid organically.';
    financedAmountTooltip = 'This amount equals to loan amount minus prepaid finance charges. Prepaid finance charges consist of renewal fee and any other deferred fees.';

    factorRateAmount: number;
    numberOfPayments: number;

    constructor(
        dbaOrName?: string,
        creditLimit?: number, interestRate?: number, paymentFrequency?: string,
        repaymentTerm?: number,
        renewalFee?: number, otherDeferredFee?: number,
        otherCashFee?: number, balanceTransfer?: number, payoff?: number) {

        super(
            dbaOrName,
            creditLimit, interestRate, paymentFrequency,
            repaymentTerm,
            renewalFee, otherDeferredFee,
            otherCashFee, balanceTransfer, payoff);

        this.prepaidFinanceChargesAmount = this.renewalFeeAmount + this.otherDeferredFeeAmount;
        this.factorRateAmount = Math.round(this.creditLimit * this.interestRate) / 100;
        this.financedAmount = this.creditLimit - this.prepaidFinanceChargesAmount;
        this.numberOfPayments = Math.ceil(this.repaymentTerm * this.paymentsPerYear / 12);
        this.paymentAmount = this.calculatePayment(this.numberOfPayments, this.creditLimit);
        this.paybackAmount = this.creditLimit + this.factorRateAmount;
        this.totalFinanceChargeAmount = this.factorRateAmount + this.prepaidFinanceChargesAmount;
        this.averageMonthlyPaymentAmount = Math.round(this.paybackAmount / this.repaymentTerm * 100) / 100;
        this.repaymentTermYearsMonths = this.getTermYearsMonths(repaymentTerm);
        this.apr = this.calculateApr(this.creditLimit, this.prepaidFinanceChargesAmount, 0, this.numberOfPayments);
    }

    protected calculatePayment(numberOfPayments: number, amount: number): number {
        const factor = Math.round(amount * this.interestRate) / 100;
        return Math.ceil((amount + factor) * 100 / numberOfPayments) / 100;
    }
}
