import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Lead } from './lead.data';
import { Observable } from 'rxjs';
import { LeadService } from './lead.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LeadResolver implements Resolve<Lead> {

  constructor(
    private leadService: LeadService,
    private router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Lead> | Promise<Lead> | Lead {
    if (route.queryParams.accessToken || route.queryParams.leadSource || route.queryParams.leadSourceDetails || route.queryParams.irclickId)
      return this.leadService.current(
        route.queryParams.accessToken, route.queryParams.leadSource, route.queryParams.leadSourceDetails, route.queryParams.irclickId
      )
      .pipe(
        tap(() => this.router.navigateByUrl('/'))
      );

    return this.leadService.current();
  }
}
