import { Component, Input } from '@angular/core';
import { ApplicationCheckoutRequirement, CheckoutRequirementStatus } from 'common';

@Component({
  selector: 'ifa-calculator-closing-requirements',
  templateUrl: './calculator-closing-requirements.component.html',
  styleUrls: ['./calculator-closing-requirements.component.scss']
})
export class CalculatorClosingRequirementsComponent {

  @Input() checkoutRequirements: ApplicationCheckoutRequirement[]

  get checkoutRequirementStatus() {
    return CheckoutRequirementStatus;
  }

  filterFunction(req: ApplicationCheckoutRequirement) {
    return req.status === CheckoutRequirementStatus.Required || req.status === CheckoutRequirementStatus.Approved;
  } 
}
