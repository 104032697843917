import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { SecurityInfoDialogComponent } from 'common';
import { Lead, LeadBusinessOwnerData } from '../../lead.data';
import { LeadService } from '../../lead.service';
// eslint-disable-next-line max-len
import { CreditApplicationTermsDialogComponent } from '../../../shared/credit-application-terms-dialog/credit-application-terms-dialog.component';

@Component({
  selector: 'ifa-owner-information',
  templateUrl: './owner-information.component.html',
  styleUrls: ['./owner-information.component.scss']
})
export class OwnerInformationComponent implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @Input()
  form: UntypedFormGroup;

  data: Lead;
  days: number[] = [];
  months: { value: number, label: string }[] = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
  ];
  years: number[] = [];
  acceptTerms: boolean;

  constructor(
    private leadService: LeadService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog
  ) {
    this._unsubscribeAll = new Subject();

    for (let i = 1; i <= 31; i++)
      this.days.push(i);

    const lastYear = new Date().getFullYear() - 18;
    const firstYear = lastYear - 100;

    for (let i = lastYear; i >= firstYear; i--)
      this.years.push(i);
  }

  ngOnInit() {
    this.leadService.obsCurrentLead
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data) {
          this.data = data;
          if (data.business.owners && data.business.owners.length) {
            if ((this.form.get('owners') as UntypedFormArray).length !== data.business.owners.length)
              data.business.owners.forEach(() => {
                this.addOwner();
              });
          } else if ((this.form.get('owners') as UntypedFormArray).length === 0)
            this.addOwner();
          this.dataInit(data);
        }
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addOwner() {
    const owners = this.form.get('owners') as UntypedFormArray;
    owners.push(this.createDefaultForm());
  }

  removeOwner(index: number) {
    const owners = this.form.get('owners') as UntypedFormArray;
    owners.removeAt(index);
  }

  createDefaultForm(): UntypedFormGroup {
    return this.formBuilder.group({
      firstName: [undefined, Validators.required],
      lastName: [undefined, Validators.required],
      email: [undefined, Validators.compose([Validators.email, Validators.required])],
      phoneNumber: [undefined, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
      address: this.formBuilder.group({
        street: [undefined, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(255)])],
        city: [undefined, Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/), Validators.minLength(2), Validators.maxLength(40)])],
        state: [undefined, Validators.required],
        zipCode: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/), Validators.maxLength(20)])],
        unitNumber: [undefined, Validators.maxLength(16)]
      }),
      birthDate: this.formBuilder.group({
        month: [undefined, Validators.required],
        day: [undefined, Validators.required],
        year: [undefined, Validators.required]
      }),
      ssn: [undefined, Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
      ficoScore: [undefined, Validators.required],
      ownership: [undefined, Validators.compose([Validators.required, Validators.max(100)])]
    });
  }

  dataInit(data: Lead) {
    if (!this.data.business) return;
    const formOwners = this.form.get('owners') as UntypedFormArray;
    let i = 0;
    this.form.reset({
      agreedToTerms: this.data.business.owners.length ? this.data.business.owners[0].agreedToTerms : false
    });
    _.forEach(this.data.business.owners, (owner: LeadBusinessOwnerData) => {
      if (formOwners.length) {
        const formGroup = formOwners.controls[i] as UntypedFormGroup;
        formGroup.reset({
          firstName: owner.firstName,
          lastName: owner.lastName,
          email: owner.email,
          phoneNumber: owner.phoneNumber,
          address: {
            street: owner.address.street,
            city: owner.address.city,
            state: owner.address.state,
            zipCode: owner.address.zipCode,
            unitNumber: owner.address.unitNumber
          },
          birthDate: {
            month: owner.birthDate.month,
            day: owner.birthDate.day,
            year: owner.birthDate.year
          },
          ssn: owner.ssn,
          ficoScore: owner.ficoScore,
          ownership: owner.ownership
        });
        this.resetForm(formGroup);
      }
      i++;
    });
  }

  emailChanged(value, index): void {
    if (index === 0)
      this.leadService.onOwner1EmailChanged.next(value);
  }

  openSSLDialog() {
    SecurityInfoDialogComponent.show(this.dialog);
  }

  openTermsDialog() {
    CreditApplicationTermsDialogComponent.show(this.dialog);
  }

  continue() {
    this.form.get('agreedToTerms').markAsDirty();
    const formOwners = this.form.get('owners') as UntypedFormArray;
    formOwners.controls.forEach((value, index) => {
      const form = formOwners.controls[index] as UntypedFormGroup;
      this.validateForm(form);
    });
  }

  resetForm(formGroup: UntypedFormGroup) {
    let control: AbstractControl = null;
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      if (control instanceof UntypedFormGroup)
        this.resetForm(control);
      else
        control.setErrors(null);
    });
  }

  validateForm(formGroup: UntypedFormGroup) {
    let control: AbstractControl = null;
    Object.keys(formGroup.controls).forEach((name) => {
      control = formGroup.controls[name];
      if (control instanceof UntypedFormGroup)
        this.validateForm(control);
      else
        control.updateValueAndValidity();
    });
  }
}

