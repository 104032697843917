<link [href]="brandingCss" rel="stylesheet">

<div class="ifa-calc-wrapper" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="start column" fxLayoutGap="24px">

    <ng-container *ngIf="locOffer">
      <h2>
        Line of Credit
      </h2>
      <h4>
        Pay only for the funds you use, no pre-payment penalties. Every time a payment is made, your line is replenished
        for futher use. Get lower payments with a longer repayment term - all payments are kept on one schedule and with
        each new draw the repayment term starts over.
      </h4>
      <ifa-line-of-credit-content [offer]="locOffer" ngClass.gt-md="layout padding-right-md">
      </ifa-line-of-credit-content>
    </ng-container>

    <ng-container *ngIf="lawOffer">
      <mat-divider *ngIf="locOffer" fxHide.gt-md></mat-divider>
      <h2>
        Case Financing
      </h2>
      <h4 class="layout padding-left padding-right">
        Grow your case load with smart case financing. Offload case costs from your balance sheet. Cover losses with
        litigation cost protection.
      </h4>
      <ifa-law-case-financing-content [offer]="lawOffer" ngClass.gt-md="layout padding-left padding-right">
      </ifa-law-case-financing-content>
    </ng-container>

    <ng-container *ngIf="termOffer">
      <mat-divider fxHide.gt-md></mat-divider>
      <h2>
        Term Loan
      </h2>
      <h4>
        Get access to funds without paying fees. Save 50% on remaining finance charges at time of full pre-payment. Once the
        loan is 50% paid down, you may be eligible for more offers and savings.
      </h4>
      <ifa-term-loan-content [offer]="termOffer" ngClass.gt-md="layout padding-left">
      </ifa-term-loan-content>
    </ng-container>

  </div>
</div>