import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApplicationService } from "../../shared/application.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ApplicationBaseData, LinkingStatus } from "common";

@UntilDestroy(this)
@Component({
  selector: "ifa-link-your-bank-page",
  templateUrl: "./link-your-bank-page.component.html",
  styleUrls: ["./link-your-bank-page.component.scss"],
})
export class LinkYourBankPageComponent implements OnInit {
  appUuid: string;
  bankLinkStatus: LinkingStatus;

  readonly LinkingStatus = LinkingStatus;

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.appUuid = params.get("uuid");

      this.applicationService
        .getByUUID(this.appUuid)
        .pipe(untilDestroyed(this))
        .subscribe((data: ApplicationBaseData) => this.initData(data));
    });
  }

  private initData(data: ApplicationBaseData) {
    this.bankLinkStatus = data.bankLinkStatus;
  }
}
