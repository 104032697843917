import { Component, Input } from '@angular/core';
import { ApplicationOffer } from 'common';

@Component({
  selector: 'ifa-calculator-payment-schedule',
  templateUrl: './calculator-payment-schedule.component.html',
  styleUrls: ['./calculator-payment-schedule.component.scss']
})
export class CalculatorPaymentScheduleComponent {

  @Input() offer: ApplicationOffer
  @Input() sampleDraw: number

}
