<ifa-share-offer *ngIf="!customerView" class="table-wrapper" [offer]="offer"></ifa-share-offer>

<ifa-calculator-draw-slider [offerCalculator]="offerCalculator" [productCode]="'LOC'"></ifa-calculator-draw-slider>

<ifa-calculator-details-table [offerCalculator]="offerCalculator" [showCommision]="!customerView && !isRenewal" [showTerm]="true"></ifa-calculator-details-table> 

<ifa-calculator-closing-requirements [checkoutRequirements]="checkoutRequirements"></ifa-calculator-closing-requirements>

<ifa-link-your-bank [applicationUuid]="uuid" [bankLinkStatus]="application?.bankLinkStatus" ></ifa-link-your-bank>

<ifa-calculator-payment-schedule [offer]="offer" [sampleDraw]="offerCalculator.sampleDraw"></ifa-calculator-payment-schedule>