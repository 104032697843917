  <mat-horizontal-stepper #horizontalStepper (selectionChange)="onStepChange($event)" [selectedIndex]="selectedIndex"
    *ngIf="!verticalLayout" linear>
    <mat-step [stepControl]="businessInfoForm">
      <ng-template matStepLabel>{{stepLabel1}}</ng-template>
      <ifa-business-information [form]="businessInfoForm"></ifa-business-information>
    </mat-step>
    <mat-step [stepControl]="ownersForm">
      <ng-template matStepLabel>{{stepLabel2}}</ng-template>
      <ifa-owner-information [form]="ownersForm"></ifa-owner-information>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{stepLabel3}}</ng-template>
    </mat-step>
  </mat-horizontal-stepper>

  <mat-vertical-stepper #verticalStepper (selectionChange)="onStepChange($event)" [selectedIndex]="selectedIndex"
    *ngIf="verticalLayout" linear>
    <mat-step [stepControl]="businessInfoForm">
      <ng-template matStepLabel>{{stepLabel1}}</ng-template>
      <ifa-business-information [form]="businessInfoForm"></ifa-business-information>
    </mat-step>
    <mat-step [stepControl]="ownersForm">
      <ng-template matStepLabel>{{stepLabel2}}</ng-template>
      <ifa-owner-information [form]="ownersForm"></ifa-owner-information>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{stepLabel3}}</ng-template>
    </mat-step>
  </mat-vertical-stepper>

  <ifa-financial-review (prevStep)="prevStep()" *ngIf="selectedIndex===2"></ifa-financial-review>
