import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

declare var google: any;

@Component({
  selector: 'ifa-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @ViewChild('inlineInput', { read: ElementRef, static: true })
  inlineInput: ElementRef;

  @ViewChild('cityInput', { read: ElementRef, static: true })
  cityInput: ElementRef;

  @Input()
  form: UntypedFormGroup;

  googleAutocompleteStreet: any;
  googleAutocompleteCity: any;
  autocompleteEnabled = true;

  constructor(private zone: NgZone) { }

  ngOnInit() {
    try {
      this.googleAutocompleteStreet = new google.maps.places.Autocomplete(this.inlineInput.nativeElement, this.googleAutocompleteOptions);
      this.googleAutocompleteStreet.addListener("place_changed", () => this.googleAutocompletePlaceChange(this.googleAutocompleteStreet));

      this.googleAutocompleteCity = new google.maps.places.Autocomplete(this.cityInput.nativeElement, this.googleAutocompleteOptionsCity);
      this.googleAutocompleteCity.addListener("place_changed", () => this.googleAutocompletePlaceChange(this.googleAutocompleteCity));
    } catch (e) {
      this.autocompleteEnabled = false;
    }
  }
  private googleAutocompleteOptions = {
    types: [],
    componentRestrictions: { country: "us" }
  };

  private googleAutocompleteOptionsCity = {
    types: ['(cities)'],
    componentRestrictions: { country: "us" }
  };

  googleAutocompleteNameMapping = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name'
  };

  googleAutocompletePlaceChange(source) {
    this.zone.run(() => {
      const place = source.getPlace();

      this.form.get('street').setValue('');
      this.form.get('city').setValue('');
      this.form.get('state').setValue('');
      this.form.get('zipCode').setValue('');
      this.form.get('unitNumber').setValue('');

      (place.address_components || []).forEach(component => {
        const addressType = component.types[0];
        if (!this.googleAutocompleteNameMapping[addressType])
          return;
        const value = component[this.googleAutocompleteNameMapping[addressType]];
        switch (addressType) {
          case 'street_number':
            this.form.get('street').setValue(value + (this.form.value.street ? ' ' + this.form.value.street : ''));
            break;
          case 'route':
            this.form.get('street').setValue((this.form.value.street ? this.form.value.street + ' ' : '') + value);
            break;
          case 'locality':
            this.form.get('city').setValue(value);
            break;
          case 'administrative_area_level_1':
            this.form.get('state').setValue(value);
            break;
          case 'postal_code':
            this.form.get('zipCode').setValue(value);
            break;
        }
      });
    });
  }

  states = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
  ];

}
