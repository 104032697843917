import { NgModule } from '@angular/core';
import { CommonModule } from 'common';
import { CarouselSliderComponent as CarouselSliderComponent } from './carousel-slider.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  declarations: [
    CarouselSliderComponent
  ],
  exports: [
    CarouselSliderComponent
  ]
})
export class CarouselSliderModule { }
