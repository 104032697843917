import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductNavigatorComponent } from './product-navigator.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        ProductNavigatorComponent
    ],
    imports: [
        MatIconModule,
        CommonModule,
        FlexLayoutModule 
    ],
    exports: [
        ProductNavigatorComponent
    ]
})
export class ProductNavigatorModule { }
