import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BrandingService, CompanyBranding } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, filter } from 'rxjs/operators';
import { LocalSettings } from './shared/local.settings';
import { EMPTY } from 'rxjs';

declare global {
  interface Window { analytics: any; }
  let identifyCalled: string;
}

@UntilDestroy()
@Component({
  selector: 'ifa-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private localSettings: LocalSettings,
    private route: ActivatedRoute,
    private router: Router,
    private brandingService: BrandingService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.initBranding();
    this.router.events.pipe(
      untilDestroyed(this),
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        if (this.localSettings.applicationFormRedirection.allowed)
          this.checkAndRedirect();
      });
  }

  private initBranding() {
    this.brandingService.getBranding$()
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      ).subscribe(data => {
        this.setDOMElements(data);
      });
  }

  private setDOMElements(brandingData: CompanyBranding): void {
    this.titleService.setTitle(`${brandingData?.dba ?? 'Idea Financial'}`);
  }

  private checkAndRedirect() {
    const currentPath = this.router.url.split('?')[0];

    const queryParams = this.route.snapshot.queryParams;
    const queryString = new URLSearchParams(queryParams).toString();

    if (!currentPath || currentPath === '/') {
      const targetUrl = `${this.localSettings.applicationFormRedirection.url}?${queryString}`;
      window.location.href = targetUrl;
    }
  }
}
