import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "common";
import { Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { Lead } from "../../lead.data";
import { LeadService } from "../../lead.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { takeUntil } from "rxjs/operators";
import { AppSettings } from "../../../../app.settings";
import { Uppy } from "@uppy/core";
import { DashboardOptions } from "@uppy/dashboard";
import {
  getUppyDashboardOptions,
  getUppyInstance,
} from "./financial-review-uppy-config";

@Component({
  selector: "ifa-financial-review",
  templateUrl: "./financial-review.component.html",
  styleUrls: ["./financial-review.component.scss"],
})
export class FinancialReviewComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  @Output()
  prevStep = new EventEmitter<any>();

  lead: Lead;
  submitSub: Subscription;
  files: File[] = [];
  width: any;

  uppy: Uppy;
  uppyDashboardOptions: DashboardOptions;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private leadService: LeadService,
    breakpointObserver: BreakpointObserver,
    private settings: AppSettings
  ) {
    this._unsubscribeAll = new Subject();

    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) this.width = 300;
        else {
          this.width = 500;
        }
      });
  }

  ngOnInit() {
    this.leadService.obsCurrentLead
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data) this.dataInit(data);
      });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dataInit(lead: Lead) {
    this.lead = lead;
    const endpoint = `${this.settings.leads.url}/api/lead/submitFiles`;
    this.uppy = getUppyInstance(endpoint, lead.accessToken);
    this.uppyDashboardOptions = getUppyDashboardOptions(this.width);

    this.uppy.on("file-added", (file: unknown) => {
      this.files.push(file as File);
    });
    this.uppy.on("complete", (result) => {
      if (result.failed && result.failed.length > 0) {
        return;
      }

      this.submitLead();
    });
  }

  submit() {
    if (this.submitSub) {
      return;
    }

    if (this.files.length) {
      window.analytics.track("App submitted with attachments");
      window.analytics.page("App submitted with attachments");
      this.submitWithFiles();
    } else {
      window.analytics.track("App submitted without attachments");
      window.analytics.page("App submitted without attachments");
      this.submitLead();
    }
  }

  submitWithFiles() {
    this.uppy.getState()?.error ? this.uppy.retryAll() : this.uppy.upload();
  }

  submitLead() {
    this.submitSub = this.leadService
      .submit(this.lead)
      .subscribe(this.submitSuccess.bind(this), this.submitError.bind(this));
  }

  submitError(error) {
    this.submitSubClear();
    this.messageService.error(error);
  }

  submitSubClear() {
    if (this.submitSub) this.submitSub.unsubscribe();
    this.submitSub = null;
  }

  submitSuccess() {
    const irclickId = this.lead.business.irclickId;
    const referrerContext = !irclickId
      ? {}
      : { context: { referrer: { type: "impactRadius", id: irclickId } } };
    window.analytics.track("Application submitted", this.lead, referrerContext);

    this.submitSubClear();
    if (this.files.length)
      this.router.navigate(["/thank-you"], {
        queryParams: { includeAttachments: true },
      });
    else
      this.router.navigate(["/thank-you"], {
        queryParams: { includeAttachments: false },
      });
  }

  goBack() {
    this.prevStep.emit();
  }
}
