import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RepaymentNavigatorComponent } from './repayment-navigator.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [
        RepaymentNavigatorComponent
    ],
    imports: [
        MatFormFieldModule,
        MatSelectModule,
        CommonModule,
        FlexLayoutModule 
    ],
    exports: [
        RepaymentNavigatorComponent
    ]
})
export class RepaymentNavigatorModule { }
