import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { Lead, LeadBusinessOwnerData } from '../../lead.data';
import { LeadService } from '../../lead.service';

@Component({
  selector: 'ifa-lead-flow-stepper',
  templateUrl: './lead-flow-stepper.component.html',
  styleUrls: ['./lead-flow-stepper.component.scss']
})
export class LeadFlowStepperComponent implements OnInit, AfterViewChecked, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  @ViewChild('horizontalStepper') horizontalStepper;
  @ViewChild('verticalStepper') verticalStepper;

  stepper: MatStepper;
  verticalLayout: boolean;
  navigateNextSub: Subscription;
  data: Lead;
  agreedToTerms: boolean;
  existingLead: boolean;
  selectedIndex: number;
  businessInfoForm: UntypedFormGroup;
  ownersForm: UntypedFormGroup;

  stepLabel1 = 'Business information';
  stepLabel2 = 'Owner information';
  stepLabel3 = 'Financial review';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private leadService: LeadService,
    private formBuilder: UntypedFormBuilder,
    breakpointObserver: BreakpointObserver
  ) {
    this._unsubscribeAll = new Subject();

    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches)
        this.verticalLayout = true;
      else
        this.verticalLayout = false;
      this.stepper = this.verticalLayout ? this.verticalStepper : this.horizontalStepper;
    });

    this.businessInfoForm = this.formBuilder.group({
      base: this.formBuilder.group({
        firstName: new UntypedFormControl(undefined, { validators: [Validators.required, Validators.maxLength(40)], updateOn: 'blur' }),
        lastName: new UntypedFormControl(undefined, { validators: [Validators.required, Validators.maxLength(80)], updateOn: 'blur' }),
        legalEntityName: new UntypedFormControl(undefined, { validators: [Validators.required, Validators.maxLength(255)], updateOn: 'blur' }),
        email: new UntypedFormControl(undefined, { validators: Validators.compose([Validators.email, Validators.required]), updateOn: 'blur' })
      }),
      // eslint-disable-next-line max-len
      referralCode: [undefined, Validators.compose([Validators.pattern(/^[A-Za-z0-9]*$/), Validators.minLength(7), Validators.maxLength(10)])],
      taxId: [undefined, Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
      phone: [undefined, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      address: this.formBuilder.group({
        street: [undefined, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(255)])],
        // eslint-disable-next-line max-len
        city: [undefined, Validators.compose([Validators.required, Validators.pattern(/^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/), Validators.minLength(2), Validators.maxLength(40)])],
        state: [undefined, Validators.required],
        zipCode: [undefined, Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-?\d{4})?$/), Validators.maxLength(20)])],
        unitNumber: [undefined, Validators.maxLength(16)]
      }),
      averageMonthlySales: [undefined, Validators.compose([Validators.required, Validators.min(1), Validators.max(9999999)])],
      timeInBusiness: [undefined, Validators.required],
      entityType: [undefined, Validators.required],
    });

    this.ownersForm = this.formBuilder.group({
      agreedToTerms: new UntypedFormControl(undefined, Validators.requiredTrue),
      owners: this.formBuilder.array([])
    });
  }

  ngOnInit() {
    window.analytics.page('Start Application');
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Track the navigation to a page
        window.analytics.page();
      }
      if (event instanceof NavigationEnd) {
        // Ignore for now
      }
      if (event instanceof NavigationError) {
        // Handle error
        window.analytics.track('Error', event);
      }
    });
    this.route.data
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {

        if (!data.lead) return;

        this.data = data.lead;
        this.leadService.onCurrentLeadChanged.next(data.lead);

        const irclickId = data.lead.business.irclickId;
        const referrerContext = !irclickId ? {} : { context: { referrer: { type: 'impactRadius', id: irclickId } } };

        if (this.data.business.details && this.data.business.details.legalEntityName)
          this.existingLead = true;

        window.analytics.track(this.route.routeConfig.path || 'Application', data.lead, referrerContext);
      });

    this.route.queryParamMap.subscribe(paramsMap => {
      const referralCode = paramsMap.get("referralCode");
      if (referralCode)
        this.businessInfoForm.controls.referralCode.setValue(referralCode);

      const email = paramsMap.get("email");
      if (email)
        this.businessInfoForm.controls.base.get("email").setValue(email);
    });

    let oldStatus = this.businessInfoForm.controls.base.status;
    this.businessInfoForm.controls.base.statusChanges.subscribe(status => {
      if (status === 'VALID' && status !== oldStatus)
        this.update();
      oldStatus = status;
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewChecked() {
    this.stepper = this.horizontalStepper || this.verticalStepper;
    this.cdRef.detectChanges();
  }

  prevStep() {
    this.stepper.previous();
  }

  onStepChange(event: any) {
    this.selectedIndex = event.selectedIndex;
    const irclickId = this.data.business.irclickId;
    const referrerContext = !irclickId ? {} : { context: { referrer: { type: 'impactRadius', id: irclickId } } };
    const stepLabel = this.getStepLabel(event.previouslySelectedIndex);
    window.analytics.track(stepLabel, this.data, referrerContext);
    window.analytics.page(stepLabel);

    this.update();

    if (this.verticalLayout) {
      const stepId = this.stepper._getStepLabelId(event.selectedIndex);
      const stepElement = document.getElementById(stepId);
      if (stepElement) {
        setTimeout(() => {
          stepElement.scrollIntoView(true);
        }, 250);
      }
    }
  }

  getStepLabel(index: number): string {
    switch (index) {
      case 0:
        return this.stepLabel1;
      case 1:
        return this.stepLabel2;
      case 2:
        return this.stepLabel3;
    }
  }

  update() {
    this.data.business.details = {
      legalEntityName: this.businessInfoForm.controls.base.value && this.businessInfoForm.controls.base.value.legalEntityName
        || this.data.business.details && this.data.business.details.legalEntityName,
      taxId: this.businessInfoForm.controls.taxId && this.businessInfoForm.controls.taxId.value
        || this.data.business.details && this.data.business.details.taxId,
      phoneNumber: this.businessInfoForm.controls.phone && this.businessInfoForm.controls.phone.value
        || this.data.business.details && this.data.business.details.phoneNumber,
      referralCode: this.businessInfoForm.controls.referralCode && this.businessInfoForm.controls.referralCode.value
        || this.data.business.details && this.data.business.details.referralCode,
      averageMonthlySales: this.businessInfoForm.controls.averageMonthlySales && this.businessInfoForm.controls.averageMonthlySales.value
        || this.data.business.details && this.data.business.details.averageMonthlySales,
      timeInBusiness:
        this.businessInfoForm.controls.timeInBusiness && this.businessInfoForm.controls.timeInBusiness.value != null
          ? this.businessInfoForm.controls.timeInBusiness.value
          : this.data.business.details && this.data.business.details.timeInBusiness,
      entityType:
        this.businessInfoForm.controls.entityType
          && this.businessInfoForm.controls.entityType.value != null
          ? this.businessInfoForm.controls.entityType.value
          : this.data.business.details && this.data.business.details.entityType,
      address: {
        street: this.businessInfoForm.controls.address.value && this.businessInfoForm.controls.address.value.street
          || this.data.business.details && this.data.business.details.address.street,
        city: this.businessInfoForm.controls.address.value && this.businessInfoForm.controls.address.value.city
          || this.data.business.details && this.data.business.details.address.city,
        state: this.businessInfoForm.controls.address.value && this.businessInfoForm.controls.address.value.state
          || this.data.business.details && this.data.business.details.address.state,
        zipCode: this.businessInfoForm.controls.address.value && this.businessInfoForm.controls.address.value.zipCode
          || this.data.business.details && this.data.business.details.address.zipCode,
        unitNumber: this.businessInfoForm.controls.address.value && this.businessInfoForm.controls.address.value.unitNumber
          || this.data.business.details && this.data.business.details.address.unitNumber
      }
    };

    const owners: LeadBusinessOwnerData[] = [];
    const formOwners = this.ownersForm.get('owners') as UntypedFormArray;

    if (formOwners.value[0]) {
      if (!formOwners.value[0].email || formOwners.value[0].email !== this.businessInfoForm.controls.base.value.email)
        formOwners.value[0].email = this.businessInfoForm.controls.base.value.email;
      if (!formOwners.value[0].firstName || formOwners.value[0].firstName !== this.businessInfoForm.controls.base.value.firstName)
        formOwners.value[0].firstName = this.businessInfoForm.controls.base.value.firstName;
      if (!formOwners.value[0].lastName || formOwners.value[0].lastName !== this.businessInfoForm.controls.base.value.lastName)
        formOwners.value[0].lastName = this.businessInfoForm.controls.base.value.lastName;

      _.forEach(formOwners.value, (owner: LeadBusinessOwnerData) => {
        owners.push({
          firstName: owner.firstName,
          lastName: owner.lastName,
          ssn: owner.ssn,
          email: owner.email,
          phoneNumber: owner.phoneNumber,
          address: {
            street: owner.address.street,
            city: owner.address.city,
            state: owner.address.state,
            zipCode: owner.address.zipCode,
            unitNumber: owner.address.unitNumber
          },
          birthDate: {
            day: owner.birthDate.day,
            month: owner.birthDate.month,
            year: owner.birthDate.year
          },
          ficoScore: owner.ficoScore,
          ownership: owner.ownership,
          agreedToTerms: this.ownersForm.controls.agreedToTerms.value
        });
      });
    }

    if (owners.length)
      this.data.business.owners = owners;

    if (!this.existingLead) {
      window.analytics.track('Signup Form Submission');
      this.existingLead = true;
    }

    this.leadService.update(this.data).subscribe(() =>
      this.leadService.onCurrentLeadChanged.next(this.data)
    );

  }
}
