<div class="disclosure-container">
	<p class="disclosure-header">
		OFFER SUMMARY - {{data.dbaOrName | uppercase}} LINE OF CREDIT
    </p>

	<table class="disclosure-table">
		<colgroup>
			<col />
			<col />
			<col width="55%" />
		</colgroup>
		<tr>
			<td colspan="3">
				<i>
					The following information is based on an initial draw of your full Approved Credit Limit of
                    <ifa-disclosure-field [data]="data" field="creditLimit" format="currency"></ifa-disclosure-field>
					and assumes that you will choose to make minimum monthly payments, that you miss no payments, and that you do not re-draw on this line.
					Actual payments and costs may differ substantially.
				</i>
			</td>
		</tr>
		<tr>
			<td>
				Funding Provided
			</td>
			<td>
				<ifa-disclosure-field [data]="data" field="financedAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the maximum amount of funding that {{data.dbaOrName}} will provide.
			</td>
		</tr>
		<tr *ngIf="data.totalPayoffAmount">
			<td>
    			Funds Disbursed
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="disbursedAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the amount of funds that would be disbursed directly to you under the assumptions described at the top of this disclosure.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Total Payment Amount
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="paybackAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the total dollar amount of payments you would make based on the assumptions described at the top of this disclosure.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Cost of Financing
			</td>
			<td>
                <ifa-disclosure-field [data]="data" field="totalFinanceChargeAmount" format="currency"></ifa-disclosure-field>
			</td>
			<td>
				This is the dollar cost of your financing based on the assumptions described at the top of this disclosure.
				The interest rate under your contract will adjust over time, so your actual cost of financing may vary.
			</td>
		</tr>
		<tr>
			<td>
				Estimated Initial Payment
			</td>
			<td>
				Manner: ACH
				<br />
                <ifa-disclosure-field [data]="data" field="paymentAmount" value="{{data.paymentAmount | currency}}/{{data.termName}}"></ifa-disclosure-field>
			</td>
			<td>
				The estimated initial minimum payment is payment is based on the assumptions described at the top of this disclosure, and on the initial interest rate.
				The actual rate may change over time.
			</td>
		</tr>
		<tr>
			<td>
				Prepayment
			</td>
			<td colspan="2">
				You have the right to prepay the financing in whole or in part at any time.
				There is no additional cost or discount for prepayment.
			</td>
		</tr>
	</table>
</div>