import { Component, ViewChild, ElementRef, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { confetti, ConfettiConfig } from 'dom-confetti';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { ApplicationBaseData, ApplicationOfferBundleData, OfferType, ProductCode, ProductCodeLabel } from 'common';
import { AppSettings } from '../../../app.settings';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationService } from '../../shared/application.service';
import { CurrencyPipe } from '@angular/common';

@Component({
    selector: 'ifa-summary-calculator',
    templateUrl: './summary-calculator.component.html',
    styleUrls: ['./../base/base-calculator.component.scss', './summary-calculator.component.scss']
})
export class SummaryCalculatorComponent implements OnInit, AfterViewInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    @ViewChild('confettiCanvas', { static: true }) confettiCanvas: ElementRef;

    brandingCss: SafeResourceUrl;
    application: ApplicationBaseData;
    bundles: ApplicationOfferBundleData[];
    uuid: string;
    isPreview: boolean;
    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _sanitizer: DomSanitizer,
        private settings: AppSettings,
        private _applicationService: ApplicationService,
        private currencyPipe: CurrencyPipe
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._route.data.subscribe(data => {
            this.isPreview = data.preview;
        });

        this._route.queryParamMap.subscribe(params => {
            this.uuid = params.get("uuid");

            this._applicationService.getByUUID(this.uuid)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(this.dataInit.bind(this));

            let subdomain = null;

            if (!environment.production)
                subdomain = params.get("subdomain");

            if (!subdomain)
                // eslint-disable-next-line max-len
                this.brandingCss = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.settings.brokers.url}/api/branding/loc-calculator-css`);
            else
                // eslint-disable-next-line max-len
                this.brandingCss = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.settings.brokers.url}/api/branding/loc-calculator-css?subdomain=${subdomain}`);

        });
    }

    private dataInit(data: ApplicationBaseData) {
        this.application = data;
        this.bundles = this.getBestOffersByProductType(data);
    }  

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            confetti(this.confettiCanvas.nativeElement, <ConfettiConfig>{
                angle: 90,
                spread: 360,
                startVelocity: 50,
                elementCount: 100
            });
        }, 1000);
    }

    getBestOffersByProductType(applicationData: ApplicationBaseData) : ApplicationOfferBundleData[] {
        let bestLOC: ApplicationOfferBundleData;
        let bestTerm: ApplicationOfferBundleData;
        let bestLaw: ApplicationOfferBundleData;

        applicationData.offerBundles.forEach(bundle => {
            switch (this.getProductType(bundle)) {
                case OfferType.LOC: bestLOC = this.getBetterOffer(bestLOC, bundle); break;
                case OfferType.Law: bestLaw = this.getBetterOffer(bestLaw, bundle); break;
                case OfferType.Term: bestTerm = this.getBetterOffer(bestTerm, bundle); break;
            }
        });

        let result = [];
        if (bestLOC) result.push(bestLOC);
        if (bestLaw) result.push(bestLaw);
        if (bestTerm) result.push(bestTerm);
        return result;
    }

    getBetterOffer(offer1: ApplicationOfferBundleData, offer2: ApplicationOfferBundleData) : ApplicationOfferBundleData
    {
        if (!offer2) return offer1;
        if (!offer1) return offer2;

        return offer1.offers[0].amount > offer2.offers[0].amount
            ? offer1
            : offer2;
    }

    getMaxOffer() : number
    {
        if (this.bundles)
            return Math.max(...this.bundles.map(x=>x.offers[0].amount))
    }

    getMaxRepayment(): number
    {
        if (this.bundles)
            return Math.max(...this.bundles.map(x=>x.offers[0].repaymentTerm))
    }

    getProductType(offerBundle: ApplicationOfferBundleData): OfferType {
        if (offerBundle.offers[0]?.productCode === ProductCode.LOC) {
          return OfferType.LOC
        } else if (offerBundle.offers[0]?.productCode === ProductCode.Term) {
          return OfferType.Term;
        } else if (offerBundle.offers[0]?.productCode === ProductCode.Law) {
          return OfferType.Law;
        }
    }

    getOfferTypeByProductCode(product: ProductCode): OfferType {
        switch (product) {
            case ProductCode.Term:
                return OfferType.Term;
            case ProductCode.LOC:
                return OfferType.LOC;
            case ProductCode.Law:
                return OfferType.Law;
        }
    }

    get shareOfferEmailBody() {
        let customerUrlTree = this._router.createUrlTree(['preview/summary'], { queryParams: {uuid: this.uuid}});
        let shareUrl = this._router['location']._locationStrategy._platformLocation.location.origin + customerUrlTree.toString();

        return `Congratulations! ${this.application?.businessName} have offers for financing up to ${this.currencyPipe.transform(this.getMaxOffer(), 'USD', 'symbol', '1.2-2')} and terms up to ${this.getMaxRepayment()} months.
        See the offer details here:%0D%0A${encodeURIComponent(shareUrl)}`;
    }

    productCodeLabel(code: ProductCode): string {
        return ProductCodeLabel.get(code);
    }
}
